import { gql } from '@apollo/client';

export const SEARCH_TALENT = gql`
  query searchTalents($search: TalentV2SearchInput!, $paginate: PaginateInput) {
    searchTalents(search: $search, paginate: $paginate) {
      count
      talents {
        id
        firstname
        lastname
        email
        secondaryEmails
        phone
        secondaryPhones
        jobTitle
        positionTitle {
          jobTitle {
            label
          }
        }
        status
        bloomerStatus
        remoteWork
        label
        availabilityDate
        image
        comment
        url
        owner
        cv
        tjm
        package
        location {
          city
        }
        profiles {
          url
          network
        }
        origin
        skills {
          level
          skill {
            label
          }
        }
        skillLevels {
          level
          skill {
            label
          }
        }
        jobs {
          current
          name
          label
          description
          jobTitle {
            label
          }
          period {
            startDate
            endDate
          }
          skills {
            label
          }
        }
      }
    }
  }
`;

export const FIND_TALENT = gql`
  query findTalent($id: String!) {
    findTalent(id: $id) {
      id
      firstname
      lastname
      email
      secondaryEmails
      phone
      secondaryPhones
      jobTitle
      positionTitle {
        id
        jobTitle {
          label
        }
      }
      status
      bloomerStatus
      remoteWork
      label
      availabilityDate
      summary
      comment
      cv
      image
      url
      owner
      tjm
      package
      location {
        id
        range
        address
        city
      }
      profiles {
        id
        url
        network
        talentId
      }
      origin
      skills {
        id
        level
        skill {
          id
          label
        }
      }
      skillLevels {
        level
        skill {
          id
          label
        }
      }
      jobs {
        id
        current
        name
        label
        description
        jobTitle {
          label
        }
        period {
          id
          startDate
          endDate
        }
        skills {
          label
        }
      }
      educations {
        id
        current
        name
        label
        description
        period {
          startDate
          endDate
        }
        url
      }
      languages {
        id
        label
        level
        talentId
      }
    }
  }
`;

export const LIST_SKILLS = gql`
  query listSkills($iso: Boolean) {
    listSkills(iso: $iso) {
      id
      label
    }
  }
`;

export const LIST_JOBS = gql`
  query listJobTitles($iso: Boolean!) {
    listJobTitles(iso: $iso) {
      id
      label
      alternateLabels
      iso
    }
  }
`;

export const FIND_JOBS_BY_LABEL = gql`
  query findManyJobTitleFromLabel($label: String!, $iso: Boolean) {
    findManyJobTitleFromLabel(label: $label, iso: $iso) {
      id
      label
    }
  }
`;

export const FIND_SKILLS_BY_LABEL = gql`
  query findManySkillsFromLabel($label: String!) {
    findManySkillsFromLabel(label: $label) {
      id
      label
    }
  }
`;

export const LIST_OWNERS = gql`
  query listOwners {
    listOwners {
      label
      count
    }
  }
`;

export const LIST_LANGUAGES = gql`
  query listLanguages {
    listLanguages {
      label
    }
  }
`;

export const GET_ADDRESSES = gql`
  query getAdresses($input: String!) {
    getAdresses(input: $input) {
      address
      postalCode
      city
      region
      range
      countryCode
      longitude
      latitude
    }
  }
`;

export const GET_LOGS_BY_TALENT = gql`
  query getLogsByTalent($talentId: String!, $paginate: PaginateInput!) {
    getLogsByTalent(talentId: $talentId, paginate: $paginate) {
      logs {
        id
        talent
        tam
        date
        data
        previousData
        source
        type
      }
      count
    }
  }
`;

export const JOBTITLES = gql`
  query {
    listJobTitles {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const SKILLS = gql`
  query ($iso: Boolean) {
    listSkills(iso: $iso) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

const talentBy = `{
      createdAt
      id
      firstname
      lastname
      email
      secondaryEmails
      phone
      secondaryPhones
      jobTitle
      positionTitle {
        id
        jobTitle {
          label
        }
      }
      status
      bloomerStatus
      remoteWork
      label
      availabilityDate
      summary
      comment
      cv
      image
      url
      owner
      tjm
      package
      location {
        id
        range
        address
        city
      }
      profiles {
        id
        url
        network
        talentId
      }
      origin
      skills {
        id
        level
        skill {
          id
          label
          iso
          alternateLabels
        }
      }
      skillLevels {
        level
        skill {
          id
          label
        }
      }
      jobs {
        id
        current
        name
        label
        description
        jobTitle {
          id
          label
        }
        period {
          id
          startDate
          endDate
        }
        skills {
          label
        }
      }
      educations {
        id
        current
        name
        label
        description
        period {
          id
          startDate
          endDate
        }
        url
      }
      languages {
        id
        label
        level
        talentId
      }
    }
`;

export const FIND_TALENT_BY_LINKEDIN = gql`
  query findTalentByLinkedin($url: String!) {
    findTalentByLinkedin(url: $url) 
    ${talentBy}
  }
`;

export const FIND_TALENT_BY_EMAIL = gql`
  query findTalentByEmail($email: String!) {
    findTalentByEmail(email: $email) 
    ${talentBy}
  }
`;

export const FIND_TALENT_BY_URL = gql`
  query findTalentByUrl($url: String!) {
    findTalentByUrl(url: $url) 
    ${talentBy}
  }
`;

export const FIND_TALENT_BY_LINKEDIN_LIGHT = gql`
  query findTalentByLinkedin($url: String!) {
    findTalentByLinkedin(url: $url) {
      createdAt
      id
      owner
    }
  }
`;

export const BOOM = gql`
  query boom($errorId: String!) {
    boom(errorId: $errorId) {
      id
    }
  }
`;

export const FIND_TALENT_NONISO_DIRECTJOBTITLES = gql`
  query findTalentsWithNonIsoDirectJobTitles {
    findTalentsWithNonIsoDirectJobTitles {
      id
      fullname
      label
      positionTitle {
        jobTitle {
          label
        }
      }
    }
  }
`;
