export const mailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
export const stringRegex = /^[a-zA-Z-' éèàçùîïôöäë]+$/u; // allow - and '
export const nameRegex = /^[^\s][a-zA-ZÀ-ÖØ-öø-ÿėęįīºū'’ -]*[^\s]$/;
export const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu;

export const formatSpace = (string: string): string => {
  return string.replaceAll(' ', '');
};

export const sanitize = (string: string) => {
  return string?.replaceAll(/[-[\]{}()\\/*@'§`£+?\\_.=:,!&;\\^$|%#\s]/g, '');
};

export const capitalize = (s: string): string => {
  const arrayOfS = s.split(' ');
  const capitalized = arrayOfS.map((string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  });
  return capitalized.join(' ');
};

export const removePointsAndSpaces = (value: string) => {
  return value.replace(/[.\s]/g, '');
};

export const validatePhoneForE164Regex = '^\\+[1-9]\\d{10,14}$';

export const validateEmail = (email: string): boolean => {
  if (email.toLocaleLowerCase().match(mailRegex)) {
    return true;
  } else {
    return false;
  }
};

export const validatePhone = (phoneNumber: string) => {
  const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;
  if (phoneNumber.match(regex)) {
    return true;
  } else {
    return false;
  }
};
