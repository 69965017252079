export const testLKUrl = (url: string) => {
  const LKRegex = /^https:\/\/(www\.)?linkedin\.com\/in\/[^/]+\/?$/;
  return LKRegex.test(url);
};

export const testGHUrl = (url: string) => {
  const GHRegex =
    /^https?:\/\/(?:[a-z0-9]+\.)?github\.com\/(?:(?:[\wÀ-ÖØ-öø-ÿ-]+-){0,2}[\wÀ-ÖØ-öø-ÿ]+(?:\.[\wÀ-ÖØ-öø-ÿ]+)*\/?)?$/;
  return GHRegex.test(url);
};

export const testTurnOverITUrl = (url: string) => {
  const TOITRegex = /^https:\/\/app\.turnover-it\.com\/cv-library\/\d+.$/;
  return TOITRegex.test(url);
};

export const testHelloWorkUrl = (url: string) => {
  const TOITRegex = /^https:\/\/app-recruteur\.hellowork\.com\/applicant\/detail\/\d+.$/;
  return TOITRegex.test(url);
};

export const getLKSlug = (url: string) => {
  const urlParsed = new URL(
    url
      .replace('//linkedin.com', '//www.linkedin.com')
      .replace('//fr.linkedin.com', '//www.linkedin.com')
      .replace('http://', 'https://'),
  );
  const pathnames = urlParsed.pathname.split('/');
  if (pathnames?.[1] === 'in') {
    return `${urlParsed.origin}/${pathnames[1]}/${pathnames[2]}/`;
  }
  return `${urlParsed.origin}${urlParsed.pathname}`;
};

export const getHWSlug = (profileId: number) => {
  return `https://app-recruteur.hellowork.com/applicant/detail/${profileId}`;
};

export const getTOITSlug = (profileId: number) => {
  return `https://app.turnover-it.com/cv-library/${profileId}`;
};
