import { Profile, ProfileNetwork } from '@bloomays-lib/types.shared';

export const getProfileByNetwork = (searchProfiles: Profile[], network: ProfileNetwork | string) => {
  return searchProfiles.find((profil) => profil.network?.toLocaleLowerCase() === network.toLowerCase());
};

export const mergeProfilesByNetwork = (oldProfiles: Profile[], newProfile: Profile) => {
  const updatedProfile = newProfile.network ? getProfileByNetwork(oldProfiles, newProfile.network) : undefined;
  if (!updatedProfile) {
    return [...oldProfiles, newProfile];
  } else {
    oldProfiles = oldProfiles.map((profile) => {
      if (profile === updatedProfile) {
        return {
          ...profile,
          ...newProfile,
        };
      }
      return profile;
    });
  }
  return oldProfiles;
};
