import React, { AriaRole, ForwardedRef, forwardRef, useMemo } from 'react';
import RawMuiButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/material/styles';
import { HrefLink } from './HrefLink';

const StyledHrefLink = (color: any) =>
  forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return (
      <HrefLink
        ref={ref}
        color={color}
        href={props.href as string}
        target={props.target}
        title={props.title}
        id={props.id}
      >
        {props.textButton}
      </HrefLink>
    );
  });

export type ButtonProps = {
  textButton: string;
  onClick?: (event: any) => void;
  emoji?: React.ReactNode;
  none?: string;
  disable?: boolean;
  title?: string;
  style?: Record<string, unknown>;
  id?: string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary';
  buttonType?: 'submit' | 'button' | 'reset';
  role?: AriaRole | undefined;
  sx?: SxProps<Theme>;
  href?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | string;
  loading?: boolean;
  nopadding?: boolean;
  ariaLabel?: string;
};

// use for fixing https://linear.app/bloomays/issue/TEC-1825/erreur-a-lutilisation-de-la-chrome-extension
const DummyEmoji = styled('span')(() => ({
  visibility: 'hidden',
  height: '0px',
  width: '0px',
}));

const Button = ({
  textButton,
  id,
  emoji,
  none,
  disable,
  title,
  onClick,
  size = 'medium',
  color = 'primary',
  buttonType = 'button',
  loading,
  sx,
  role,
  nopadding = false,
  ariaLabel,
  ...props
}: ButtonProps): JSX.Element => {
  if (!title) {
    title = textButton;
  }
  const loadingPosition = loading ? 'end' : undefined;

  const MemoStyledHrefLink = useMemo(() => {
    return StyledHrefLink(color);
  }, [color]);

  return (
    <MuiButton
      role={role}
      aria-label={ariaLabel}
      id={id}
      onClick={onClick}
      disabled={disable}
      title={title}
      sx={sx}
      type={buttonType}
      loading={loading}
      startIcon={emoji}
      endIcon={<DummyEmoji />}
      loadingPosition={loadingPosition}
      size={size}
      color={color}
      LinkComponent={MemoStyledHrefLink}
      {...props}
      variant="text"
    >
      <span>{textButton}</span>
    </MuiButton>
  );
};

const getCss = (
  size: 'small' | 'medium' | 'large' = 'medium',
  theme: Theme,
  color: 'primary' | 'secondary' = 'primary',
) => {
  const bgColor = color === 'primary' ? theme.palette.primary.main : theme.palette.secondary[600];

  const colorCSS = color === 'primary' ? theme.palette.paper.white : theme.palette.secondary.light;

  const colorHovered = color === 'primary' ? theme.palette.tertiary.darkPink : theme.palette.secondary.main;
  switch (size) {
    case 'small':
      return {
        padding: '6px 26px',
        bgColor,
        color: colorCSS,
        colorHovered,
        height: '32px',
      };
    default:
    case 'medium':
      return {
        padding: '8px 26px',
        bgColor,
        color: colorCSS,
        colorHovered,
        height: '40px',
      };
    case 'large':
      return {
        padding: '12px 26px',
        bgColor,
        color: colorCSS,
        colorHovered,
        height: '48px',
      };
  }
};

const MuiButton = styled(RawMuiButton)<ButtonProps>(({ theme, color: colorProps, size, nopadding }) => {
  const { bgColor, color, colorHovered, padding, height } = getCss(size, theme, colorProps);
  return {
    border: 'none',
    padding: nopadding ? '0px' : padding,
    borderRadius: '5px',
    backgroundColor: bgColor,
    color: color,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: height,
    textTransform: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colorHovered,
      transition: 'ease 0.3s',
    },
    '&:disabled': {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.grey[800],
      cursor: 'not-allowed',
      pointerEvents: 'auto',

      '&:hover': {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[800],
        cursor: 'not-allowed',
        pointerEvents: 'auto',
      },
    },
  };
}) as unknown as typeof RawMuiButton;

export default Button;
export { Button };
