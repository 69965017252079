import { AirtableFinance } from '@bloomays-lib/types.shared';
import { fixed, sortByDate } from '@bloomays-lib/utils.shared';
import uniq from 'lodash/uniq';

export const monthAbbreviation = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getTotal = (arr: any[] | null) => {
  if (!arr || arr?.length === 0) return 0;

  return arr?.reduce((acc: any, curr: { price: any }) => {
    return acc + curr?.price;
  }, 0);
};

export const getFinanceByType = (arrayFinance: any[] | null | undefined, isExpense: boolean | null): any[] | null => {
  if (!arrayFinance || arrayFinance.length === 0) return null;
  return arrayFinance?.filter((el: { isExpense: boolean }) => {
    return isExpense === el?.isExpense;
  });
};

export const getEvolutionPercentage = (number1: number, number2: number): number => {
  // Augmentation de 100% si oldNumber === zéro et number2 est positif,
  // une diminution de 100% si oldNumber === zéro et number2 est négatif,
  // Une diminution de 100% si number2 est négatif dans d'autres cas

  let oldNumber = number1;
  if (number1 <= 0) {
    oldNumber = 0;
    number2 = number2 + Math.abs(number1);
  }

  if (oldNumber === 0) {
    return number2 >= 0 ? 100 : -100; // Augmentation de 100% ou diminution de 100%
  }

  if (number2 <= 0) {
    return -100; // Diminution de 100%
  }

  const evolution = fixed(((number2 - oldNumber) / oldNumber) * 100, 2);
  return evolution;
};

export const getArrayPrice = (finances: AirtableFinance[]): any[] => {
  if (!finances || finances?.length === 0) return [];

  const groupedFinanceArr = groupedBy(finances, 'date');
  const sortedFinance = sortByDate(groupedFinanceArr);

  return sortedFinance.map((finance: any) => {
    return parseInt(
      finance[Object.keys(finance)[0]].reduce((acc: any, curr: { price: any }) => {
        return acc + curr?.price;
      }, 0),
    );
  });
};

export const calculBenefits = (arr1: any[] | null, arr2: string | any[] | undefined): number[] => {
  if (!arr1 || arr1?.length === 0) return [];
  if (!arr2 || arr2?.length === 0) return [];
  return arr1.map((el: number, i: number) => {
    const value = arr2[i] !== undefined ? arr2[i] : 0;
    return el - value;
  });
};

export const regroupByType = (finances: AirtableFinance[]): any[][] | undefined => {
  if (!finances) return;
  const financesData = [];
  const rpo: AirtableFinance[] = [];
  const cdi: AirtableFinance[] = [];
  const other: AirtableFinance[] = [];
  const freelance: AirtableFinance[] = [];
  const people: AirtableFinance[] = [];

  finances.forEach((finance: AirtableFinance) => {
    switch (finance?.financeType) {
      case 'RPO':
        return rpo.push(finance);
      case 'freelance':
      case 'IT':
        return freelance.push(finance);
      case 'CDI':
        return cdi.push(finance);
      case 'People':
        return people.push(finance);
      case 'other':
        return other.push(finance);
      default:
        return;
    }
  });
  const freeRPO = reduceArrayType(rpo);
  const freeIT = reduceArrayType(freelance);
  const CDI = reduceArrayType(cdi);
  const otherfinance = reduceArrayType(other);
  const peoplefinance = reduceArrayType(people);

  if (CDI) financesData.push(CDI);
  if (freeIT) financesData.push(freeIT);
  if (freeRPO) financesData.push(freeRPO);
  if (otherfinance) financesData.push(otherfinance);
  if (peoplefinance) financesData.push(peoplefinance);

  return financesData;
};

const reduceArrayType = (
  arr: any[],
):
  | {
      date: Date;
      price: number;
      financeType: string;
      year: string;
      isExpense: boolean;
    }[]
  | undefined => {
  if (!arr || arr.length === 0) return;

  return arr.reduce(
    (acc: { price: number }, curr: { date: Date; price: number; financeType: string; year: string }) => {
      return {
        date: curr.date,
        price: acc.price + curr.price,
        financeType: curr.financeType,
        year: curr.year,
        isExpense: false,
      };
    },
  );
};

export const calculPercentage = (finance: AirtableFinance, total: number): string => {
  if (!finance || !total || !finance.price) return 'TBC';
  return ((finance.price / total) * 100).toFixed(1);
};

export const monthOverview = (month: any[], financeArray: any[] | undefined) => {
  if (!financeArray || financeArray.length === 0) return [];

  const monthYear = uniq(
    financeArray.map((element: { date: string | number | Date; year: any; isForecast: any }) => {
      return `${month[new Date(element.date).getMonth()]} ${element.year}${
        element?.isForecast ? '(Prévision)' : '(Réel)'
      }`;
    }),
  );

  return monthYear.slice().sort((a: string | number | Date, b: string | number | Date) => +new Date(a) - +new Date(b));
};

export const groupedBy = (array: any[] | null | undefined, property = 'date'): null | any[] => {
  if (!array || array.length === 0) return [];
  if (!property) return null;

  const uniqDateArr = uniq(
    array.map((el: { [x: string]: any }) => {
      return el[property];
    }),
  );
  return uniqDateArr.map((el: any) => {
    return {
      [el]: array.filter((element: { [x: string]: any }) => {
        return el === element[property];
      }),
    };
  });
};
