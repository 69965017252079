import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery, useMutation } from '@apollo/client';
import { LoaderSkeleton, SingleLineText } from '@bloomays-lib/ui.shared';
import { InscriptionBloomer } from '../organisms/InscriptionBloomer';
import { notify, cancel } from '../../helpers/toastify';

import Title from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';

import {
  CHECK_INVITATION_TOKEN,
  ME_FULL,
  UPSERT_BLOOMER,
  FINISH_INVITATION,
  UPSERT_BLOOMER_SOCIETY,
} from '@bloomays-lib/adapter.api-bloomer';

import FallbackError from '../organisms/FallbackError';
import { BrowserBloomer, BrowserMe, ISociety } from '@bloomays-lib/types.shared';
import { errorLogger } from '../../helpers/error';
import { Logger } from '../../services/serviceLogger';

const logger = Logger('BloomerInvitation');

type BloomerInvitationMeData = {
  me: BrowserMe;
};

const BloomerInvitation = () => {
  const { token } = useParams<{ token: string }>();
  const [uploading, setUploading] = useState(false);

  if (!token) {
    throw new Error('Missing token params');
  }

  const [registration, setRegistration] = useState<{ bloomer?: BrowserBloomer; society?: ISociety }>({
    bloomer: undefined,
    society: undefined,
  });
  const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery<BloomerInvitationMeData>(ME_FULL);
  const [upsertBloomer] = useMutation<{ upsertBloomer: BrowserBloomer }>(UPSERT_BLOOMER, {
    refetchQueries: [
      {
        query: ME_FULL,
      },
    ],
  });
  const [finishInvitation] = useMutation<{ finishInvitation: boolean }, { token: string }>(FINISH_INVITATION, {
    variables: { token: token },
    refetchQueries: [
      {
        query: ME_FULL,
      },
      {
        query: CHECK_INVITATION_TOKEN,
        variables: { token: token },
      },
    ],
  });
  const [upsertBloomerSociety] = useMutation<{
    upsertBloomerSociety: ISociety;
  }>(UPSERT_BLOOMER_SOCIETY, {
    refetchQueries: [
      {
        query: ME_FULL,
      },
    ],
  });
  const {
    data: dataInvitation,
    error: errorInvitation,
    loading: loadingInviation,
  } = useQuery<{
    checkInvitationToken: {
      recordId: string;
      status: 'done' | 'not started' | 'on going';
      mission: string[];
    };
  }>(CHECK_INVITATION_TOKEN, {
    variables: { token: token },
  });

  const missionfForSociety = dataInvitation?.checkInvitationToken.mission;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      if (registration.bloomer && registration.society) {
        setUploading(true);
        notify('info', 'Upload en cours de tes données!', null, {
          hideProgressBar: true,
          autoClose: undefined,
          toastId: 'uploadOnboarding',
        });
        try {
          const bloomer = registration.bloomer;
          await upsertBloomer({
            variables: {
              input: {
                recordId: bloomer.recordId,
                firstname: bloomer.firstname,
                lastname: bloomer.lastname,
                email: bloomer.email,
                secondaryEmail: bloomer.secondaryEmail,
                phoneNumber: bloomer.phoneNumber,
                slack: bloomer.slack,
                accountManager: [bloomer.accountManagerId],
                credentialID: bloomer.credentialID,
                communicationType: bloomer?.communicationType,
              },
            },
          });
          const society = registration.society;
          await upsertBloomerSociety({
            variables: {
              input: {
                name: society.name,
                recordId: society.recordId,
                status: society.status,
                siret: society.siret,
                KBIS: society.KBIS,
                URSSAFVigilanceCertificate: society.URSSAFVigilanceCertificate,
                RCPVigilanceCertificate: society.RCPVigilanceCertificate,
                bloomerBillingMission: missionfForSociety,
                IBAN: society.IBAN,
                BIC: society.BIC,
                portage: society.portage || false,
                tva: society.tva,
                addressCountryCode: society.addressCountryCode,
              },
            },
          });
          await finishInvitation();
          cancel('uploadOnboarding');
          notify('success', 'Ton profil a bien été mis à jour ! :)', null, {
            hideProgressBar: true,
            autoClose: undefined,
          });
          document.location.href = '/dashboard';
        } catch (error: any) {
          setUploading(false);
          cancel();
          notify('error', 'Profil non mis à jour 😓', error);
          errorLogger(error, {
            extraInfos: 'extraErrorInfos',
            state: registration,
          });
          setRegistration({ bloomer: undefined, society: undefined });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registration.bloomer, registration.society]);

  const upsertBloomerData = async (bloomer: BrowserBloomer) => {
    await setRegistration((previousSate) => ({
      ...previousSate,
      bloomer: bloomer,
    }));
    return bloomer;
  };

  const upsertSocietyData = async (society: ISociety) => {
    await setRegistration((previousSate) => ({
      ...previousSate,
      society: society,
    }));
    return society;
  };

  useEffect(() => {
    if (!errorInvitation) {
      return;
    }
    errorLogger(errorInvitation, {
      extraInfos: 'Impossible to retrieve infos, error checking invitation token.',
      state: {},
    });
  }, [errorInvitation]);

  useEffect(() => {
    if (!errorMe) {
      return;
    }
    errorLogger(errorMe, {
      extraInfos: 'Impossible to retrieve infos about me.',
      state: {},
    });
  }, [errorMe]);

  logger.debug('on render', registration, dataInvitation, uploading, dataMe, missionfForSociety);

  if (dataInvitation?.checkInvitationToken?.status === 'done') {
    document.location.href = '/dashboard';
    return <LoaderSkeleton height={600} width={800} />;
  }

  if (errorInvitation || errorMe) {
    return <FallbackError errorText="Ton invitation ne semble pas valide !" />;
  }

  if (loadingInviation || loadingMe) {
    return <LoaderSkeleton height={600} width={800} />;
  }

  if (dataInvitation?.checkInvitationToken.status === 'on going' && dataMe) {
    return (
      <div>
        <Title textTitle="Bienvenue sur notre outil !" />
        <SingleLineText text="Voici un petit questionnaire qui va beaucoup nous aider pour ton onBoarding !" />
        <InscriptionBloomer
          me={dataMe?.me}
          uploading={uploading}
          onSocietyDataValidated={upsertSocietyData}
          onBloomerDataValidated={upsertBloomerData}
        />
      </div>
    );
  }
  return <FallbackError errorText="Ton invitation ne semble pas valide !" />;
};

export default WithErrorBoundary(BloomerInvitation);
