import { DashboardMissionData, KPIType, MonthMissionKPI } from '@bloomays-lib/types.shared';
import { Cell } from '../components/molecules/TwoDTable';
import { ColorPalette, MissionPlanningProps } from '../components/organisms/MissionPlanning';
import { fixed, translate } from '@bloomays-lib/utils.shared';

const customOrder = ['M', 'D', 'NS', 'F', 'EB', 'BE', 'BR'];

export const formatMissionPlanning = (
  inputData: DashboardMissionData,
  colorPalette: ColorPalette,
  openMissionsPopin: MissionPlanningProps['openMissionsPopin'],
): Cell[][] => {
  const formattedData: Cell[][] = [];

  customOrder.forEach((_key) => {
    const key = _key as KPIType;
    const { currentYear, lastYear, average, diff } = inputData[key];
    const keyName = translate(`KPI.${key}`);

    formattedData.push(
      formatCategoryData(
        colorPalette,
        key,
        `${keyName} (${translate('random.currentYear')})`,
        currentYear,
        average.currentYear,
        false,
        openMissionsPopin,
      ),
    );

    formattedData.push(
      formatCategoryData(
        colorPalette,
        key,
        `${keyName} (${translate('random.lastYear')})`,
        lastYear,
        average.lastYear,
        false,
        openMissionsPopin,
      ),
    );

    formattedData.push(
      formatCategoryData(colorPalette, key, `${keyName} (${translate('random.diff')})`, diff, null, true),
    );
  });

  return formattedData;
};

const formatCategoryData = (
  colorPalette: ColorPalette,
  key: KPIType,
  columnName: string,
  data: MonthMissionKPI[],
  average: number | null,
  diffRow: boolean,
  openMissionsPopin?: MissionPlanningProps['openMissionsPopin'],
): Cell[] => {
  const formattedData: Cell[] = [
    {
      columnName: 'KPI',
      value: columnName,
      backgroundColor: colorPalette[key],
      color: ['F', 'BE', 'EB'].includes(key) ? undefined : colorPalette.white,
    },
  ];

  for (const entry of data) {
    let color = undefined;
    let value: string | number = entry.count;
    if (diffRow && entry.count !== 0) {
      color = entry.count > 0 ? colorPalette.green : colorPalette.red;
      value = entry.count > 0 ? `+${entry.count}` : entry.count;
    }
    formattedData.push({
      columnName: entry.month,
      value,
      color,
      onClick:
        openMissionsPopin && entry.count > 0
          ? () => openMissionsPopin(key, entry.month, entry.missionIds, entry.estimatedMissionIds || [])
          : undefined,
    });
  }

  formattedData.push({
    columnName: translate('random.average'),
    value: average ? fixed(average, 2) : null,
  });

  return formattedData;
};
