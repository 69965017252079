import { useState, useCallback, useDeferredValue, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import {
  DataGridPremium,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridColumnResizeParams,
  GridEventListener,
  GridFeatureMode,
  GridFilterItem,
  GridFilterModel,
  GridRowClassNameParams,
  GridRowModesModel,
  GridSortModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import CustomToolbar from '../atoms/CustomToolbar';
import { SxProps, Theme } from '@mui/material/styles';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ReactI18NextChild } from 'react-i18next';
import debounce from 'lodash/debounce';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import noop from 'lodash/fp/noop';
import { Logger } from '../../services/serviceLogger';
import useLocalStorage from 'use-local-storage';
import map from 'lodash/fp/map';

const logger = Logger('DataGrid');

const setWidth = (columns: GridColDef[], size: Record<string, number>) => {
  return map((c) => {
    const width = size[c.field];
    return {
      ...c,
      width: width || c.width || undefined,
    };
  }, columns);
};

export type DataGridProps = {
  rows: any;
  columns: GridColDef[];
  pageSize?: number;
  sx?: SxProps<Theme>;
  getRowId?: (row: any) => string;
  hideFooterPagination: boolean;
  onCellDoubleClick?: (params: any) => void;
  getRowClassName: (params: GridRowClassNameParams) => string;
  handleRequestSort?: (e: any, prop: string, orderDirection: string) => void;
  getRows?: (v?: any) => void;
  sortingMode?: GridFeatureMode;
  filterMode?: GridFeatureMode;
  handleSearch?: (value: string, item?: GridFilterItem) => void;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  fetchLoading?: boolean;
  pagination?: boolean;
  editable?: boolean;
  processRowUpdate?: (updatedRow: any, originalRow: any) => void;
  onProcessRowUpdateError?: (error: any) => void;
  editMode?: 'row' | 'cell';
  rowModesModel?: GridRowModesModel;
  onRowModesModelChange?: (rowModesModel: GridRowModesModel, details: GridCallbackDetails<any>) => void;
  onRowEditStop?: GridEventListener<'rowEditStop'>;
  isCellEditable?: (params: GridCellParams) => boolean;
  childrenToolBar?: ReactI18NextChild | Iterable<ReactI18NextChild>;
  checkboxSelection?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  initialState?: GridInitialStatePremium;
  pinnedColumns?: { left?: Array<string>; right?: Array<string> };
  columnGroupingModel?: GridColumnGroupingModel;
  uniqueDatagridId: string;
};

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
  },
  '& .header': {
    backgroundColor: theme.palette.secondary.dark,
    color: 'white',
  },
  '& .cellStyle': {
    borderLeft: '1px solid lightgrey',
  },
})) as typeof DataGridPremium;

const DataGrid = ({
  getRows,
  rows,
  columns: _columns,
  pageSize,
  sx,
  hideFooterPagination,
  getRowId,
  onCellDoubleClick,
  getRowClassName,
  handleRequestSort,
  handleSearch,
  onFilterModelChange,
  sortingMode = 'client',
  filterMode = 'client',
  fetchLoading,
  pagination = false,
  processRowUpdate,
  onProcessRowUpdateError,
  editMode = 'cell',
  rowModesModel,
  onRowModesModelChange,
  onRowEditStop,
  isCellEditable,
  childrenToolBar,
  checkboxSelection,
  apiRef: apiRefExternal,
  initialState,
  pinnedColumns,
  columnGroupingModel,
  uniqueDatagridId,
}: DataGridProps) => {
  const [stateWidth, setStateWidth] = useLocalStorage<Record<string, number>>(
    `datagrid-filtermodel-${uniqueDatagridId}-columns`,
    {},
  );
  const columns = setWidth(_columns, stateWidth);
  const [stateColumns, setStateColumns] = useState<GridColDef[]>(columns);

  const [filterModel, setFilterModel] = useLocalStorage<GridFilterModel | undefined>(
    `datagrid-filtermodel-${uniqueDatagridId}`,
    undefined,
  );

  const deferredstateColumns = useDeferredValue<GridColDef[]>(stateColumns);
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: pageSize || 2,
    page: 0,
  });

  let apiRef = useGridApiRef();
  if (apiRefExternal) {
    apiRef = apiRefExternal;
  }

  useEffect(() => {
    setStateColumns(setWidth(_columns, stateWidth));
  }, [_columns]);

  useEffect(() => {
    return apiRef.current.subscribeEvent('rowExpansionChange', (params) => {
      const haveSize = stateColumns.some((c) => c.field === '__row_group_by_columns_group__');
      if (params.childrenExpanded && !haveSize) {
        apiRef.current.autosizeColumns({ includeOutliers: true }).catch((e) => logger.error(e));
      }
    });
  }, [apiRef, stateColumns]);

  useCallback(
    (sortModel: GridSortModel) => {
      if (handleRequestSort && sortModel[0] && sortModel[0].field && sortModel[0].sort)
        handleRequestSort(null, sortModel[0].field, sortModel[0].sort);
    },
    [handleRequestSort],
  );

  const onColumnResize = debounce((params: GridColumnResizeParams, event: MuiEvent, details: GridCallbackDetails) => {
    if (
      params.colDef.field === '__row_group_by_columns_group__' &&
      !stateColumns.some((c) => c.field === '__row_group_by_columns_group__')
    ) {
      setStateColumns((previousState) => {
        return [...previousState, params.colDef];
      });
    }

    setStateWidth((previousState) => {
      return { ...previousState, [params.colDef.field]: params.width };
    });
  }, 200);

  const onFilterChange = useCallback(
    (filterModelArgs: GridFilterModel) => {
      setFilterModel(filterModelArgs);
      if (handleSearch && filterModelArgs && filterModelArgs.items.length > 0) {
        for (const item of filterModelArgs.items) {
          handleSearch(item.value, item);
        }
      }
      if (getRows && handleSearch && filterModelArgs?.items?.length === 0) {
        getRows({
          variables: {
            reasearchField: '',
          },
        });
      }
    },
    [handleSearch, getRows, setFilterModel],
  );

  const defaultPageSize = pageSize || 50;

  return (
    <Box>
      <StyledDataGrid
        apiRef={apiRef}
        sx={sx}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          panel: {
            anchorEl: filterButtonEl,
          },
          toolbar: {
            setFilterButtonEl,
            children: childrenToolBar,
          },
        }}
        initialState={initialState}
        onCellClick={noop} // added to avoid double click to activate actions
        onCellDoubleClick={onCellDoubleClick}
        rows={rows}
        getRowId={getRowId}
        columns={deferredstateColumns}
        loading={rows?.length === 0 || fetchLoading ? true : false}
        paginationModel={paginationModel}
        pageSizeOptions={[defaultPageSize, defaultPageSize * 2, defaultPageSize * 3]}
        onPaginationModelChange={setPaginationModel}
        density="compact"
        hideFooterPagination={hideFooterPagination}
        getRowClassName={getRowClassName}
        sortingMode={sortingMode}
        filterMode={filterMode}
        onFilterModelChange={onFilterChange}
        pagination={pagination}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        editMode={editMode}
        rowModesModel={rowModesModel}
        onRowModesModelChange={onRowModesModelChange}
        onRowEditStop={onRowEditStop}
        isCellEditable={isCellEditable}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
        onColumnResize={onColumnResize}
        pinnedColumns={pinnedColumns}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        filterModel={filterModel && filterModel?.items ? filterModel : undefined}
      />
    </Box>
  );
};

export default DataGrid;
export { DataGrid };
