import { useEffect, useMemo, useState } from 'react';
import { errorLogger } from '../../helpers/error';
import { notify } from '../../helpers/toastify';
import { styled } from '@mui/material/styles';
import { SingleLineText, TitlePart } from '@bloomays-lib/ui.shared';
import { DatePicker } from '../atoms/DatePicker';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import {
  BLOOMER_RELAUNCH_CRA,
  CLIENT_RELAUNCH_CRA,
  CREATE_ACTIVITY_CUSTOMER_INVOICE_RETRY,
  CREATE_ACTIVITY_SUPPLIER_INVOICE_RETRY,
  GET_CRA_BY_MISSION,
  GET_CRA_BY_MONTH,
  JOBS_COMPLETED_SUBSCRIPTION,
  JOBS_ERRORED_SUBSCRIPTION,
  VALIDATE_CRA,
} from '@bloomays-lib/adapter.api-bloomer';
import { CRAStatus, CRADashboardItemGQL, Job, InvoiceStatus, AirtableActivity } from '@bloomays-lib/types.shared';
import { ApolloError, useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import ActivityDashboard from '../organisms/ActivityDashboard';
import Stepper from '../organisms/Stepper';
import { useParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { Logger } from '../../services/serviceLogger';
const logger = Logger('MonthlyActivityDashboard');

const statusCRA: CRAStatus[] = [
  'Non crée',
  'Signature non créee',
  'Attente signature Bloomer',
  'Attente signature Client',
  'Validé client',
];

const setTitle = (month: Date, missionId?: string) => {
  if (missionId) {
    return `Activités pour la mission ${missionId}`;
  }
  return `Activités pour le mois de ${month?.toLocaleDateString('fr-FR', {
    month: 'long',
  })} ${month?.getFullYear()}`;
};

const notifyError = (t: (key: string, options: unknown) => string) => (error: ApolloError, message: string) => {
  errorLogger(error, {
    extraInfos: message,
  });
  notify('error', t('randomLKError', { ns: 'random' }), error);
};

const sendNotification = (type: 'info' | 'success', message: string) => {
  notify(type, message, null, {
    hideProgressBar: true,
    autoClose: 1000,
  });
};

const MonthlyActivityDashboard = (): JSX.Element => {
  const { id: missionId } = useParams<{ id: string }>();
  const { t } = useTranslation(['missionPlanning', 'random', 'KPI', 'mission']);
  const sendError = notifyError(t);
  const optionLocalStorage = {
    serializer: (date: Date | undefined) => (date || new Date()).toISOString(),
    parser: (dateStr: string) => (dateStr ? new Date(dateStr) : new Date()),
  };
  const [date, setDate] = useLocalStorage<Date>('MonthlyActivityDashboardDate', new Date(), optionLocalStorage);
  const [datePickerValue, setDatePickerValue] = useLocalStorage(
    'MonthlyActivityDashboardDatePicker',
    new Date(),
    optionLocalStorage,
  );

  const [refresher, setRefresher] = useState<number>(0);

  const gqlGetCRA = missionId ? GET_CRA_BY_MISSION : GET_CRA_BY_MONTH;
  type gqlReturnType = { getCraByMonth?: CRADashboardItemGQL[]; getCraByMission?: CRADashboardItemGQL[] };
  const variablesGetCRA = useMemo(() => {
    return missionId ? { missionId: missionId } : { month: date.toISOString() };
  }, [missionId, date]);

  const [loadCRA, { data: dataCRA, loading: loadingCRA, error: fetchingErrorCRA, refetch }] =
    useLazyQuery<gqlReturnType>(gqlGetCRA, {
      context: { clientName: 'api.bloomers' },
      variables: variablesGetCRA,
    });

  const [rowsCRADashboardItemGQL, setRowsCRADashboardItemGQL] = useState<CRADashboardItemGQL[]>(
    dataCRA?.getCraByMission || dataCRA?.getCraByMonth || [],
  );

  useEffect(() => {
    const craDataLoaded = dataCRA?.getCraByMission || dataCRA?.getCraByMonth;
    if (craDataLoaded) {
      setRowsCRADashboardItemGQL(craDataLoaded);
      logger.debug('useEffect rows', craDataLoaded);
    }
  }, [dataCRA?.getCraByMission, dataCRA?.getCraByMonth]);

  const [
    createActivityCustomerInvoiceRetry,
    { loading: loadingCreateActivityCustomerInvoiceRetry, error: errorCreateActivityCustomerInvoiceRetry },
  ] = useMutation<
    {
      createActivityCustomerInvoiceRetry: Job;
    },
    {
      activityRecordID: string;
    }
  >(CREATE_ACTIVITY_CUSTOMER_INVOICE_RETRY);

  const [
    createActivitySupplierInvoiceRetry,
    { loading: loadingCreateSupplierCustomerInvoiceRetry, error: errorCreateSupplierCustomerInvoiceRetry },
  ] = useMutation<
    {
      createActivitySupplierInvoiceRetry: Job;
    },
    {
      activityRecordID: string;
      force: boolean;
      byPassComplianceCheck: boolean;
    }
  >(CREATE_ACTIVITY_SUPPLIER_INVOICE_RETRY);

  const [validateCRA] = useMutation<
    {
      validateCRA: AirtableActivity;
    },
    {
      activityRecordID: string;
      validate: boolean;
    }
  >(VALIDATE_CRA, {
    context: { clientName: 'api.bloomers' },
    refetchQueries: [
      {
        query: gqlGetCRA,
        variables: variablesGetCRA,
        context: { clientName: 'api.bloomers' },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [sendClientRelaunch, { data: dataClientRelaunch, loading: loadingClientRelaunch, error: errorClientRelaunch }] =
    useMutation(CLIENT_RELAUNCH_CRA);
  const [
    sendBloomerRelaunch,
    { data: dataBloomerRelaunch, loading: loadingBloomerRelaunch, error: errorBloomerRelaunch },
  ] = useMutation(BLOOMER_RELAUNCH_CRA);

  const findActivity = (activityRecordID: string, prev: CRADashboardItemGQL[]) => {
    const index = prev.findIndex((item) => item.activityId === activityRecordID);
    if (index === -1) {
      return { item: null, index: -1 };
    }
    return { item: prev[index], index };
  };

  const onDataSubscription = (newStatus: InvoiceStatus, jobId?: string, jobQueueName?: string) => {
    if (jobQueueName === 'createActivitySupplierInvoice' && jobId) {
      const activityId = jobId.split('-')[1];
      void refetch().then((data) => {
        logger.debug('refetch createActivitySupplierInvoice', data);
        setRefresher((prev) => prev + 1);
        const newRows = data.data.getCraByMission || data.data.getCraByMonth || [];
        const { item: newRow } = findActivity(activityId, newRows);
        if (newRow?.bloomerInvoiceStatus) {
          setRowsCRADashboardItemGQL((prev) => {
            return updateRowsStateStatus(prev, activityId, newRow.bloomerInvoiceStatus, 'bloomerInvoiceStatus');
          });
        }
      });
    }
    if (jobQueueName === 'createActivityCustomerInvoice' && jobId) {
      const activityId = jobId.split('-')[1];
      void refetch().then((data) => {
        logger.debug('refetch createActivityCustomerInvoice', data);
        setRefresher((prev) => prev + 1);
        const newRows = data.data.getCraByMission || data.data.getCraByMonth || [];
        const { item: newRow } = findActivity(activityId, newRows);
        if (newRow?.clientInvoiceStatus) {
          setRowsCRADashboardItemGQL((prev) => {
            return updateRowsStateStatus(prev, activityId, newRow.clientInvoiceStatus, 'clientInvoiceStatus');
          });
        }
      });
    }
  };

  const updateRowsStateStatus = (
    prev: CRADashboardItemGQL[],
    activityRecordID: string,
    newStatus: InvoiceStatus,
    field: 'bloomerInvoiceStatus' | 'clientInvoiceStatus',
  ) => {
    const { index } = findActivity(activityRecordID, prev);
    if (index !== -1) {
      return prev.map((item, i) => {
        if (i === index) {
          if (field === 'bloomerInvoiceStatus') {
            return { ...item, bloomerInvoiceStatus: newStatus };
          }
          if (field === 'clientInvoiceStatus') {
            return { ...item, clientInvoiceStatus: newStatus };
          }
        }
        return item;
      });
    }
    return prev;
  };

  const { data: dataSubscriptionError } = useSubscription<{
    jobErrored: { jobId: string; jobQueueName: string; jobError: { message: string } };
  }>(JOBS_ERRORED_SUBSCRIPTION, {
    onData: (v) => onDataSubscription('En erreur', v.data.data?.jobErrored.jobId, v.data.data?.jobErrored.jobQueueName),
  });

  const { data: dataSubscriptionComplete } = useSubscription<{ jobCompleted: { jobId: string; jobQueueName: string } }>(
    JOBS_COMPLETED_SUBSCRIPTION,
    {
      onData: (v) =>
        onDataSubscription('En erreur', v.data.data?.jobCompleted.jobId, v.data.data?.jobCompleted.jobQueueName),
    },
  );

  useEffect(() => {
    if (dataSubscriptionError?.jobErrored) {
      notify('warning', 'Traitement executé mais avec des erreurs', dataSubscriptionError.jobErrored.jobError.message, {
        hideProgressBar: true,
        autoClose: false,
        toastId: `dataSubscriptionComplete-${dataSubscriptionError.jobErrored.jobId}`,
      });
    }
  }, [dataSubscriptionError]);

  useEffect(() => {
    if (dataSubscriptionComplete?.jobCompleted) {
      notify('warning', 'Traitement executé avec succès', dataSubscriptionComplete.jobCompleted.jobId, {
        hideProgressBar: true,
        autoClose: false,
        toastId: `dataSubscriptionComplete-${dataSubscriptionComplete.jobCompleted.jobId}`,
      });
    }
  }, [dataSubscriptionComplete]);

  useEffect(() => {
    void loadCRA({ variables: variablesGetCRA });
  }, [missionId, loadCRA, variablesGetCRA]);

  useEffect(() => {
    fetchingErrorCRA && sendError(fetchingErrorCRA, 'Error, unable to retrieve getCra  data!');
  }, [sendError, fetchingErrorCRA]);

  useEffect(() => {
    errorCreateActivityCustomerInvoiceRetry &&
      sendError(errorCreateActivityCustomerInvoiceRetry, 'Error lors de la regeneration de facture client!');
  }, [sendError, errorCreateActivityCustomerInvoiceRetry]);

  useEffect(() => {
    errorCreateSupplierCustomerInvoiceRetry &&
      sendError(errorCreateSupplierCustomerInvoiceRetry, 'Error lors de la regeneration de facture bloomer!');
  }, [sendError, errorCreateSupplierCustomerInvoiceRetry]);

  useEffect(() => {
    errorClientRelaunch && sendError(errorClientRelaunch, 'Error lors de la relance client!');
  }, [sendError, errorClientRelaunch]);

  useEffect(() => {
    errorBloomerRelaunch && sendError(errorBloomerRelaunch, 'Error lors de la relance Bloomer!');
  }, [sendError, errorBloomerRelaunch]);

  useEffect(() => {
    loadingClientRelaunch && sendNotification('info', 'Notification en cours ! :)');
    loadingBloomerRelaunch && sendNotification('info', 'Notification en cours ! :)');
    loadingCreateActivityCustomerInvoiceRetry &&
      sendNotification('info', 'Regénération de la facture client en cours ! :)');
    loadingCreateSupplierCustomerInvoiceRetry &&
      sendNotification('info', 'Regénération de la facture bloomer en cours ! :)');
  }, [
    loadingClientRelaunch,
    loadingBloomerRelaunch,
    loadingCreateActivityCustomerInvoiceRetry,
    loadingCreateSupplierCustomerInvoiceRetry,
  ]);

  useEffect(() => {
    dataBloomerRelaunch &&
      dataBloomerRelaunch.relaunchBloomerCRA &&
      sendNotification('success', 'Relance bloomer envoyée avec succès ! :)');
  }, [dataBloomerRelaunch]);

  useEffect(() => {
    dataClientRelaunch &&
      dataClientRelaunch.relaunchInviteClientCRA &&
      sendNotification('success', 'Relance client envoyée avec succès ! :)');
  }, [dataClientRelaunch]);

  return (
    <div>
      <HeaderContainer>
        <TitlePart id="titleActivities" textTitle={setTitle(date, missionId)} />
        <InfoContainer>
          <StepperContainer>
            <Stepper active={0} title="Liste des étapes des statuts de CRA :" steps={statusCRA} />
          </StepperContainer>
          {!missionId && (
            <ContainerSelectDate>
              <ContainerDate>
                <SingleLineText text={t('pickDate', { ns: 'random' })} />
              </ContainerDate>
              <DatePicker
                views={['year', 'month']}
                value={datePickerValue}
                onAccept={(date): void => {
                  date && setDate(date);
                }}
                handleChange={(date): void => {
                  setDatePickerValue(date as Date);
                }}
                label={t('selectedMonth', { ns: 'random' })}
              />
            </ContainerSelectDate>
          )}
        </InfoContainer>
      </HeaderContainer>
      <ContainerDiv>
        <ActivityDashboard
          uniqueActivityDashboardId={`MonthlyActivityDashboard`}
          key={refresher}
          missionMode={!!missionId}
          loading={loadingCRA}
          rows={rowsCRADashboardItemGQL}
          forceCRA={async (v) => {
            await validateCRA({
              variables: {
                validate: v.variables.force,
                activityRecordID: v.variables.activityRecordID,
              },
            }).catch((error) => {
              sendError(error, 'Error lors de la validation du CRA!');
            });
          }}
          createActivityCustomerInvoice={async (v) => {
            let prevStatus: InvoiceStatus;
            setRowsCRADashboardItemGQL((prev) => {
              const { item } = findActivity(v.variables.activityRecordID, prev);
              prevStatus = item?.clientInvoiceStatus || 'Non créée';
              return updateRowsStateStatus(prev, v.variables.activityRecordID, 'En cours', 'clientInvoiceStatus');
            });
            await createActivityCustomerInvoiceRetry(v).catch((error) => {
              setRowsCRADashboardItemGQL((prev) => {
                return updateRowsStateStatus(prev, v.variables.activityRecordID, prevStatus, 'clientInvoiceStatus');
              });
            });
          }}
          createActivitySupplierInvoice={async (v) => {
            let prevStatus: InvoiceStatus;
            setRowsCRADashboardItemGQL((prev) => {
              const { item } = findActivity(v.variables.activityRecordID, prev);
              prevStatus = item?.bloomerInvoiceStatus || 'Non créée';
              return updateRowsStateStatus(prev, v.variables.activityRecordID, 'En cours', 'bloomerInvoiceStatus');
            });
            await createActivitySupplierInvoiceRetry(v).catch((error) => {
              setRowsCRADashboardItemGQL((prev) => {
                return updateRowsStateStatus(prev, v.variables.activityRecordID, prevStatus, 'bloomerInvoiceStatus');
              });
            });
          }}
          sendClientRelaunch={sendClientRelaunch}
          sendBloomerRelaunch={sendBloomerRelaunch}
        />
      </ContainerDiv>
    </div>
  );
};

export default WithErrorBoundary(MonthlyActivityDashboard);

const StepperContainer = styled('div')(() => ({
  width: '50%',
}));

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ContainerDiv = styled('div')(({ theme }) => ({
  margin: 'auto',
  width: '90%',
}));

const ContainerSelectDate = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const ContainerDate = styled('div')(() => ({
  marginRight: '10px',
}));

const InfoContainer = styled('div')(() => ({
  display: 'flex',
  width: '90%',
  justifyContent: 'space-between',
}));
