import styled from '@emotion/styled';
import { Button, InformationTip, TitlePart } from '@bloomays-lib/ui.shared';
import Checkbox from '../atoms/Checkbox';
import { IMissionCardDetails } from '@bloomays-lib/types.shared';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabelAndSelectField from './LabelAndSelectField';
import { useMutation } from '@apollo/client';
import { GET_ONE_FULL_MISSION, UPDATE_MISSION } from '@bloomays-lib/adapter.api-bloomer';
import { cancel, notify } from '../../helpers/toastify';
import isEqual from 'lodash/fp/isEqual';
import Stack from '@mui/material/Stack';
import { Slider } from '../atoms/Slider';
import RawFormControlLabel from '@mui/material/FormControlLabel';

export type ModifyMissionProps = {
  mission: IMissionCardDetails;
  setPendingMissionModify: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
};

export const ModifyMission = ({ mission, setPendingMissionModify, handleClose }: ModifyMissionProps): JSX.Element => {
  const { t } = useTranslation(['mission', 'random', 'notify']);

  const [updateMissionMutation, { loading: savingMission }] = useMutation(UPDATE_MISSION, {
    refetchQueries: [
      {
        query: GET_ONE_FULL_MISSION,
        variables: {
          recordId: mission.recordId,
        },
      },
    ],
  });

  const initialState = {
    contractType: mission.contractType || '',
    isDeal: !!mission.isDeal,
    replacement: !!mission.replacement,
    hoursPerDay: mission.hoursPerDays,
  };
  const [contractType, setContractType] = useState<string>(mission.contractType || '');
  const [isDeal, setIsDeal] = useState<boolean>(!!mission.isDeal);
  const [replacement, setReplacement] = useState<boolean>(!!mission.replacement);
  const [hoursPerDay, setHoursPerDay] = useState<number>(mission.hoursPerDays);
  const [modified, setModified] = useState<boolean>(false);

  useEffect(() => {
    setPendingMissionModify(savingMission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingMission]);

  useEffect(() => {
    const currentState = {
      contractType,
      isDeal,
      replacement,
      hoursPerDay,
    };
    setModified(!isEqual(initialState, currentState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractType, isDeal, replacement, hoursPerDay]);

  const handleChangeContract = (value: string) => {
    setContractType(value);
  };

  return (
    <ContainerModifyMission>
      <ContainerTitle>
        <TitlePart textTitle={t('modifyMissionTitle')} />
      </ContainerTitle>
      <ContainerCheckbox>
        <Checkbox
          name="replacement"
          checked={replacement}
          label={t('isReplacement')}
          labelPlacement="start"
          onChange={(e, checked) => {
            setReplacement(checked);
          }}
        />
      </ContainerCheckbox>
      <ContainerCheckbox>
        <Checkbox
          name="isDeal"
          checked={isDeal}
          label={t('isDeal')}
          labelPlacement="start"
          onChange={(e, checked) => {
            setIsDeal(checked);
          }}
        />
      </ContainerCheckbox>
      {!mission?.contractType && (
        <ContainerContractType>
          <LabelAndSelectField
            handlechange={(e) => handleChangeContract(e.target.value as string)}
            options={[
              { value: 'devis', label: 'Devis envoyé par Bloomays' },
              { value: 'clientContract', label: 'Contrat de prestation de service rédigé par le Client' },
              { value: 'bloomaysContract', label: 'Contrat de prestation de service envoyé par Bloomays' },
            ]}
            textLabel={t('contractTypeTitle')}
            value={contractType}
            defaultOption
            name={`contractType`}
            required={false}
          />
        </ContainerContractType>
      )}
      <ContainerSlider>
        <Stack direction="row" spacing={2} alignItems={'baseline'}>
          <FormControlLabel
            label={"Durée de travail d'une journée"}
            labelPlacement={'start'}
            control={
              <>
                <Slider
                  sx={{ width: '100px', marginLeft: '10px' }}
                  min={0}
                  max={12}
                  step={0.5}
                  onChangeCommitted={(e, value) => {
                    setHoursPerDay(Array.isArray(value) ? value?.[0] : value);
                  }}
                  defaultValue={hoursPerDay}
                  label={"Base horairie d'un journée de travail"}
                  marks={[]}
                />
                <InformationTip
                  children={"⚠️ Attention la modification n'est pas retractive. Seuls les nouveaux CRA seront affectés"}
                />
              </>
            }
          />
          <div>{hoursPerDay}</div>
        </Stack>
      </ContainerSlider>
      <ContainerButton>
        <Button
          textButton={t('save', { ns: 'random' })}
          disable={!modified}
          onClick={async () => {
            const payload = {
              isDeal: isDeal,
              replacement: replacement,
              contractType: contractType ? contractType : undefined,
              hoursPerDays: hoursPerDay,
            };

            try {
              notify('info', t('updateInProgress', { ns: 'notify' }), null, {
                autoClose: 800,
                hideProgressBar: true,
              });
              const response = await updateMissionMutation({
                variables: {
                  input: {
                    recordId: mission.recordId,
                    ...payload,
                  },
                },
              });
              if (response?.data.updateMission) {
                notify('success', t('updateSuccess', { ns: 'notify' }));
                handleClose();
              }
            } catch (e: any) {
              cancel();
              if (e.graphqlErrors?.[0]?.extensions?.code === 'BAD_USER_INPUT') {
                const invalidArgs = e.graphqlErrors?.[0]?.extensions?.invalidArgs as Array<{
                  missing: string;
                }>;
                if (invalidArgs)
                  notify(
                    'error',
                    t('missingFieldError', {
                      ns: 'notify',
                      missingField: invalidArgs[0].missing as string,
                    }),
                  );
              } else {
                notify('error', t('randomUpdateError', { ns: 'notify' }));
              }
            }
          }}
        />
      </ContainerButton>
    </ContainerModifyMission>
  );
};

const ContainerModifyMission = styled('div')(() => ({
  padding: '50px',
  textAlign: 'left',
}));

const ContainerTitle = styled('div')(() => ({
  paddingBottom: '50px',
}));

const ContainerCheckbox = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerSlider = styled('div')(() => ({
  textAlign: 'left',
}));

const ContainerButton = styled('div')(() => ({
  textAlign: 'right',
  paddingLeft: '50px',
}));

const ContainerContractType = styled('div')(() => ({
  textAlign: 'left',
}));

const FormControlLabel = styled(RawFormControlLabel)(() => ({
  marginLeft: '0px ',
}));
