import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ItemJobs, ItemLanguages, ItemSkills } from '@bloomays-lib/types.shared';
import { InformationTip, TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { TitlePart } from '@bloomays-lib/ui.shared';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@bloomays-lib/ui.shared';
import { capitalize } from '@bloomays-lib/utils.shared';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { useEffect, useState } from 'react';
import { notify } from '../../helpers/toastify';

export type ItemsEditionProps = {
  items: ItemLanguages[] | ItemJobs[] | ItemSkills[];
  type: 'skills' | 'languages';
  setNewRow: (e: any) => any;
  setNews: (e: any) => any;
  upsert: (e: any) => any;
  loading: boolean;
  itemList: string[];
  talentId?: string;
};

const ItemsEdition = ({ items, type, setNewRow, setNews, loading, upsert, itemList, talentId }: ItemsEditionProps) => {
  const { t } = useTranslation(['itemsEdition', 'languages', 'random', 'talentSheet']);
  const [TMPItems, setTMPItems] = useState(items);
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    setTMPItems(items);
  }, [items]);

  const langOptions = [
    { label: 'Débutant', value: 'Débutant' },
    { label: 'Intermédiaire', value: 'Intermédiaire' },
    { label: 'Courant', value: 'Courant' },
    { label: 'Bilingue', value: 'Bilingue' },
  ];

  const skillOptions = [
    { label: 'Junior', value: 'Junior' },
    { label: 'Confirmé', value: 'Confirmé' },
    { label: 'Senior', value: 'Senior' },
  ];

  const parseSkillToString = (level?: number) => {
    switch (level) {
      case 0.4:
      case 0.5:
      case 0.6:
        return 'Confirmé';
      case 0.7:
      case 0.8:
      case 0.9:
      case 1:
        return 'Senior';
      case 0:
      case 0.1:
      case 0.2:
      case 0.3:
        return 'Junior';
      default:
        return '';
    }
  };

  const parseSkillToNumb = (level?: string) => {
    switch (level) {
      case 'Confirmé':
        return 0.4;
      case 'Senior':
        return 0.7;
      case 'Junior':
        return 0.1;
      default:
        return 0;
    }
  };

  const parseLangToString = (level?: number) => {
    switch (level) {
      case 0:
      case 0.1:
      case 0.2:
        return 'Débutant';
      case 0.3:
      case 0.4:
      case 0.5:
        return 'Intermédiaire';
      case 0.6:
      case 0.7:
      case 0.8:
        return 'Courant';
      case 0.9:
      case 1:
        return 'Bilingue';
      default:
        return '';
    }
  };

  const parseLangToNumb = (level?: string) => {
    switch (level) {
      case 'Débutant':
        return 0.2;
      case 'Intermédiaire':
        return 0.5;
      case 'Courant':
        return 0.8;
      case 'Bilingue':
        return 1;
      default:
        return 0;
    }
  };

  const itemsListNoIsoAndIso = [...itemList, ...TMPItems.map((item) => item.label)];

  return (
    <StyledItemsEdition>
      <StyledContainerTitle>
        <TitlePart
          variant={'heading5'}
          textTitle={type === 'languages' ? t('languageManagement') : t('skillManagement')}
        />
        {type === 'skills' && <InformationTip>{t('disableSkillInput', { ns: 'itemsEdition' })}</InformationTip>}
      </StyledContainerTitle>
      {TMPItems &&
        TMPItems.length > 0 &&
        TMPItems?.map((item, i) => {
          return (
            <StyledDivRow key={`item-${item?.label}-${i}`}>
              <ValidationTextField
                label={type === 'languages' ? t('lang') : t('skill')}
                onChange={(e: any) => {
                  const existingItem = TMPItems.filter((item) => item.label === e.target.value);
                  if (existingItem.length > 0) {
                    notify('warning', t('cannotDuplicateLangs', { ns: 'talentSheet' }), null, {
                      hideProgressBar: false,
                      autoClose: 3000,
                    });
                    return;
                  }
                  setNewRow((prevState: { label: string; level: number }[]) => {
                    const rowToUpdate = prevState.filter((prev) => prev.label === item.label)[0];
                    const TMPPrevState = prevState.filter((tmp) => tmp.label !== item.label);
                    return [
                      ...TMPPrevState,
                      {
                        ...rowToUpdate,
                        label: e.target.value,
                      },
                    ];
                  });
                  setNews((prevState: { label: string; level: number }[]) => {
                    const rowToUpdate = prevState.filter((prev) => prev.label === item.label)[0];
                    const TMPPrevState = prevState.filter((tmp) => tmp.label !== item.label);
                    return [
                      ...TMPPrevState,
                      {
                        ...rowToUpdate,
                        label: e.target.value,
                      },
                    ];
                  });
                  setHasChanged(true);
                }}
                value={
                  item?.label
                    ? type === 'languages'
                      ? capitalize(t(item?.label, { ns: 'languages' }))
                      : item.label
                    : '-'
                }
                size={'small'}
                type={TypeInput.select}
                placeholder={'-Choisir une option-'}
                disabled={item.selected === true}
                options={itemsListNoIsoAndIso.map((item) => {
                  return { value: item, label: item };
                })}
              />
              <ValidationTextField
                label={t('level')}
                onChange={(e: any) => {
                  setNewRow((prevState: { label: string; level: number }[]) => {
                    const rowToUpdate = prevState.filter((prev) => prev.label === item.label)[0];
                    const TMPPrevState = prevState.filter((tmp) => tmp.label !== item.label);
                    return [
                      ...TMPPrevState,
                      {
                        ...rowToUpdate,
                        level:
                          type === 'languages' ? parseLangToNumb(e.target.value) : parseSkillToNumb(e.target.value),
                      },
                    ];
                  });
                  setNews((prevState: { label: string; level: number }[]) => {
                    const TMPPrevState = prevState.filter((tmp) => tmp.label !== item.label);
                    return [
                      ...TMPPrevState,
                      {
                        ...item,
                        level:
                          type === 'languages' ? parseLangToNumb(e.target.value) : parseSkillToNumb(e.target.value),
                      },
                    ];
                  });
                  setHasChanged(true);
                }}
                value={type === 'languages' ? parseLangToString(item?.level) : parseSkillToString(item?.level)}
                size={'small'}
                type={TypeInput.select}
                placeholder={'-Choisir une option-'}
                options={type === 'languages' ? langOptions : skillOptions}
              />
              <StyledDeleteLogo
                onClick={() => {
                  setNewRow(TMPItems.filter((TMPItem) => TMPItem.label !== item.label));
                  setNews((prevState: { label: string; level: number }[]) => {
                    const TMPPrevState = prevState.filter((tmp) => tmp.label !== item.label);
                    return [
                      ...TMPPrevState,
                      {
                        ...item,
                        isToDelete: true,
                      },
                    ];
                  });
                  setHasChanged(true);
                }}
              />
            </StyledDivRow>
          );
        })}
      <StyledRowContainerAdd
        onClick={() => {
          setNewRow((prevState: any) => [...prevState, { label: '', level: undefined, talentId: talentId }]);
        }}
      >
        <AddIcon />
        <SingleLineText
          variant={'body2Medium'}
          text={type === 'skills' ? t('addSkill', { ns: 'talentSheet' }) : t('addLang', { ns: 'talentSheet' })}
        />
      </StyledRowContainerAdd>
      <StyledSaveDiv>
        <Button
          textButton={t('save', { ns: 'random' })}
          onClick={() => {
            setHasChanged(false);
            upsert(TMPItems);
          }}
          disable={loading || !hasChanged || TMPItems.filter((item) => item.label === '').length !== 0}
        />
      </StyledSaveDiv>
    </StyledItemsEdition>
  );
};

export default ItemsEdition;
export { ItemsEdition };

const StyledItemsEdition = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.grey[200]};
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    gap: 4px;
    padding: 24px;
    min-width: 720px;
  `,
);

const StyledDivRow = styled('div')(
  () => `
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;
  `,
);

const StyledDeleteLogo = styled(DeleteOutlinedIcon)(
  () => `
    margin-top: 0.45em;
    &:hover {
      cursor: pointer;
    }
  `,
);

const StyledContainerTitle = styled('div')(
  () => `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-bottom: 30px;
    `,
);

const StyledSaveDiv = styled('div')(
  () => `
    display: flex;
    justify-content: flex-end;
    `,
);

const StyledRowContainerAdd = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  text-decoration: underline;
  color: ${theme.palette.grey[100]};
  float: left;
  width: 230px;

  &:hover {
    cursor: pointer;
  }
  `,
);
