/* eslint-disable jsx-a11y/accessible-emoji */
import { convertDateToStringFormat } from '@bloomays-lib/utils.shared';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams, gridClasses } from '@mui/x-data-grid-premium';

import RawDataGrid from './DataGrid';
import { AllMissionGQLItemResult, IMission, MissionType } from '@bloomays-lib/types.shared';
import { styled } from '@mui/material/styles';
import { LoaderSkeleton, ActionButton } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import TagMissionType from './TagMissionType';

export type TableMissionsProps = {
  data: AllMissionGQLItemResult[];
  fetchLoading: boolean;
  pageSize?: number;
};

const TableMissions = ({ data, fetchLoading, pageSize = 50 }: TableMissionsProps): JSX.Element => {
  const { t } = useTranslation(['mission']);

  const columns: GridColDef<AllMissionGQLItemResult>[] = [
    {
      field: 'clientSocietyName',
      headerName: t('clientSocietyName'),
      width: 200,
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<AllMissionGQLItemResult>) => params.row.clientSocietyName?.[0],
    },
    {
      field: 'bloomerFullName',
      headerName: t('bloomerFullName'),
      groupable: true,
      sortable: true,
      filterable: true,
      width: 250,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<AllMissionGQLItemResult>) => params.row.bloomerFullName?.[0],
    },
    {
      field: 'label',
      headerName: t('label'),
      width: 250,
      groupable: true,
      sortable: true,
      filterable: true,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
    },
    {
      field: 'type',
      headerName: t('Type'),
      groupable: true,
      sortable: true,
      filterable: true,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'singleSelect',
      valueOptions: [MissionType.IT, MissionType.RPO, MissionType.People],
      renderCell: (params) => {
        if (params.row.type) {
          return <TagMissionType type={params.row.type} />;
        }
      },
    },
    {
      field: 'beginMission',
      headerName: t('beginMission'),
      width: 150,
      groupable: true,
      sortable: true,
      filterable: true,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<AllMissionGQLItemResult>) =>
        params.row?.beginMission ? new Date(params.row?.beginMission) : undefined,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value ? convertDateToStringFormat(params.value, 'dd/MM/yyyy') : undefined,
    },
    {
      field: 'endMission',
      headerName: t('endMission'),
      width: 150,
      groupable: true,
      sortable: true,
      filterable: true,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<AllMissionGQLItemResult>) =>
        params.row?.endMission ? new Date(params.row?.endMission) : undefined,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value ? convertDateToStringFormat(params.value, 'dd/MM/yyyy') : undefined,
    },
    {
      field: 'bloomerSocietyName',
      headerName: t('bloomerSocietyName'),
      groupable: true,
      sortable: true,
      filterable: true,
      width: 200,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<AllMissionGQLItemResult>) => params.row.bloomerSocietyName?.[0],
    },
    {
      field: 'isActive',
      headerName: t('isActive'),
      filterable: true,
      groupable: true,
      sortable: true,
      width: 200,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'boolean',
    },
    {
      field: 'replacement',
      headerName: t('replacement'),
      filterable: true,
      groupable: true,
      sortable: true,
      width: 200,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'boolean',
    },
    {
      field: 'details',
      headerName: t('details'),
      width: 90,
      headerClassName: 'header',
      sortable: false,
      groupable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'cellStyle',
      type: 'actions',
      renderCell: (params) => {
        if (params.row.recordId) {
          return (
            <>
              <ActionButton
                actionType="Edit"
                size="small"
                color="secondary"
                variant="contained"
                href={`/missions/${params.row?.recordId}`}
                target="_self"
              />
              <ActionButton
                actionType="Preview"
                color="secondary"
                variant="contained"
                href={`/missions/${params.row?.recordId}`}
                target="_blank"
              />
            </>
          );
        }
      },
    },
  ];

  return (
    <div>
      {data?.length === 0 || typeof data === 'undefined' ? (
        <LoaderSkeleton height={400} width={1200} />
      ) : (
        <DataGrid
          uniqueDatagridId="table-missions"
          initialState={{
            sorting: {
              sortModel: [{ field: 'clientSocietyName', sort: 'asc' }],
            },
            pinnedColumns: {
              left: ['details'],
            },
          }}
          fetchLoading={fetchLoading}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          hideFooterPagination={false}
          sortingMode="client"
          filterMode="client"
          rows={data}
          getRowId={(row: IMission) => row.recordId as string}
          columns={columns}
          pageSize={pageSize}
          pagination={true}
          sx={{
            width: '100%',
          }}
        />
      )}
    </div>
  );
};

export default TableMissions;

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));
