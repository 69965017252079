import { styled } from '@mui/material/styles';
import {
  BackgroundType,
  EducationTalent,
  LanguagesTalent,
  ITalent,
  ItemJobs,
  ItemLanguages,
  ItemSkills,
  Language,
} from '@bloomays-lib/types.shared';
import { EmptyState, ItemTalentLevelProps, SingleLineText } from '@bloomays-lib/ui.shared';
import { TitlePart } from '@bloomays-lib/ui.shared';
import orderBy from 'lodash/orderBy';
import { CardItem } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';
import empty from '../../graphic-charter/emptyCV.svg';
import AddIcon from '@mui/icons-material/Add';
import ItemTalentLevelList from '../molecules/ItemTalentLevelList';
import Modal from '../atoms/Modal';
import ItemsEdition from './ItemsEdition';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FIND_TALENT, DELETE_EDUCATION, UPSERT_EDUCATION, UPSERT_LANGUAGE } from '@bloomays-lib/adapter.api-talent';
import CreateIcon from '@mui/icons-material/Create';
import { notify } from '../../helpers/toastify';
import { useTheme } from '@mui/material/styles';
import EducationEdition from './EducationEdition';
import { capitalize, constructDateDetails, findDuplicate } from '@bloomays-lib/utils.shared';
import { errorLogger } from '../../helpers/error';

export type TalentSheetFormationProps = {
  talent?: ITalent;
};

const TalentSheetFormation = ({ talent }: TalentSheetFormationProps) => {
  const { t } = useTranslation(['talentSheet', 'random', 'languages', 'notify']);
  const theme = useTheme();
  const [educations, setEducations] = useState(talent?.educations);
  const [languages, setLanguages] = useState<ItemLanguages[]>();
  const [newLanguages, setNewLanguages] = useState<ItemLanguages[]>([]);
  const [education] = useState<EducationTalent>({
    id: '',
    type: BackgroundType.Education,
    name: '',
    label: '',
    talentId: talent?.id,
  });
  const [open, setOpen] = useState<{
    items: ItemJobs[] | ItemSkills[] | ItemLanguages[];
    show: boolean;
    type: 'skills' | 'languages';
  }>({ items: [], show: false, type: 'skills' });
  const [openEduc, setOpenEduc] = useState<{
    item: EducationTalent | undefined;
    show: boolean;
  }>({ item: undefined, show: false });

  const handleCloseEduc = () => {
    setOpenEduc({ ...openEduc, show: false });
  };

  const [upsertLanguages, { loading: loadingLang, error: errorLanguages }] = useMutation<{
    upsertLanguages: Language[];
  }>(UPSERT_LANGUAGE, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const [upsertEducationTalent, { loading: loadingEducTalent }] = useMutation<{
    upsertEducationTalent: EducationTalent;
  }>(UPSERT_EDUCATION, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const [deleteEducationTalent] = useMutation<{
    deleteEducationTalent: EducationTalent;
  }>(DELETE_EDUCATION, {
    context: { clientName: 'api.talents' },
    refetchQueries: [
      {
        query: FIND_TALENT,
        variables: {
          id: talent?.id,
        },
        context: { clientName: 'api.talents' },
      },
    ],
  });

  const handleClose = () => {
    setOpen({ ...open, show: false });
    setLanguages(languages?.filter((lang) => lang.label !== ''));
  };

  useEffect(() => {
    if (talent) {
      setEducations(talent.educations);
      setLanguages(
        talent.languages?.map((lang) => {
          return { ...lang, selected: true, id: lang.id as string };
        }),
      );
    }
  }, [talent]);

  const listItemsLang = [
    capitalize(t('french', { ns: 'languages' })),
    capitalize(t('english', { ns: 'languages' })),
    capitalize(t('spanish', { ns: 'languages' })),
    capitalize(t('german', { ns: 'languages' })),
    capitalize(t('russian', { ns: 'languages' })),
    capitalize(t('chinese', { ns: 'languages' })),
    capitalize(t('korean', { ns: 'languages' })),
    capitalize(t('arabic', { ns: 'languages' })),
    capitalize(t('portuguese', { ns: 'languages' })),
    capitalize(t('italian', { ns: 'languages' })),
  ];

  const prepareLang = (languages?: LanguagesTalent[]): ItemTalentLevelProps[] => {
    const items: ItemTalentLevelProps[] = [];
    if (!languages) return [];

    languages?.forEach((lang) => {
      const capitalized: string = capitalize(t(lang.label, { ns: 'languages' })) || t(lang.label, { ns: 'languages' });

      items.push({
        skill: { label: capitalized, id: lang.id as string },
        level: lang?.level,
      });
    });
    return items;
  };

  const upsertLang = async () => {
    const preparedLang = newLanguages.map((lang) => {
      delete lang.selected;
      return { ...lang, talentId: talent?.id };
    });
    const double = findDuplicate(languages || []);
    if (double.length > 0) {
      notify('warning', t('duplicateLangs'), null, {
        hideProgressBar: true,
        autoClose: 10000,
      });
      return;
    }
    const updated = await upsertLanguages({
      variables: {
        languageTalent: preparedLang,
      },
    });
    if (updated.data?.upsertLanguages && updated.data?.upsertLanguages?.length > 0) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      setNewLanguages([]);
      handleClose();
    }
  };

  if (errorLanguages) {
    errorLogger(errorLanguages, {
      extraInfos: 'Internal server error, impossible to retrieve jobs data !',
    });
    notify('error', t('randomLKError', { ns: 'notify' }), errorLanguages);
  }

  const upsertEduc = async (educ: EducationTalent) => {
    const updated = await upsertEducationTalent({
      variables: {
        education: { ...educ, talentId: talent?.id },
      },
    });
    if (updated.data?.upsertEducationTalent && updated.data?.upsertEducationTalent?.id) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      handleCloseEduc();
    }
  };

  const deleteEducation = async (id?: string) => {
    const deleted = await deleteEducationTalent({
      variables: {
        educationTalentId: id,
      },
    });
    if (deleted.data?.deleteEducationTalent && deleted.data?.deleteEducationTalent?.id) {
      notify('success', t('modifSaved', { ns: 'random' }), null, {
        hideProgressBar: false,
        autoClose: 3000,
      });
      handleCloseEduc();
    }
  };

  return (
    <div>
      <Modal
        open={open.show}
        onClose={handleClose}
        additionnalCSS={{
          overflowY: 'scroll',
          border: 'none',
          backgroundColor: theme.palette.grey[200],
          borderRadius: '20px',
        }}
      >
        <ItemsEdition
          talentId={talent?.id}
          loading={loadingLang}
          items={languages || []}
          type={open.type}
          setNewRow={setLanguages}
          setNews={setNewLanguages} // TODO
          upsert={upsertLang}
          itemList={listItemsLang}
        />
      </Modal>
      <Modal
        open={openEduc.show}
        onClose={handleCloseEduc}
        additionnalCSS={{
          border: 'none',
          borderRadius: '20px',
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <EducationEdition
          loading={loadingEducTalent}
          item={openEduc.item}
          upsert={upsertEduc}
          deleteEduc={deleteEducation}
        />
      </Modal>
      <StyledXP>
        <TitlePart variant="subtitle2Medium" textTitle={t('lang', { ns: 'talentSheet' })} />
        <StyledRowContainerAdd
          onClick={() => {
            setOpen({
              type: 'languages',
              items: languages || [],
              show: true,
            });
          }}
        >
          <AddIcon />
          <SingleLineText variant={'body2Medium'} text={t('addLangs', { ns: 'talentSheet' })} />
        </StyledRowContainerAdd>
        {languages && languages?.[0]?.label !== '' && languages.length > 0 ? (
          <StyledContainerXP>
            <ItemTalentLevelList items={prepareLang(languages) || []} />
          </StyledContainerXP>
        ) : (
          <StyledEmptyState>
            <EmptyState image={empty} text={t('noLang')} />
          </StyledEmptyState>
        )}
      </StyledXP>
      <StyledXP>
        <TitlePart variant="subtitle2Medium" textTitle={t('school', { ns: 'talentSheet' })} />
        <StyledRowContainerAdd
          onClick={() => {
            setOpenEduc({
              item: education,
              show: true,
            });
          }}
        >
          <AddIcon />
          <SingleLineText variant={'body2Medium'} text={t('addEducation', { ns: 'talentSheet' })} />
        </StyledRowContainerAdd>
        {educations && educations.length > 0 ? (
          orderBy(educations, (item) => item?.period?.startDate, ['desc'])?.map((education, i) => {
            return (
              <div key={education.id}>
                <StyledRowContainerAdd
                  onClick={() => {
                    setOpenEduc({
                      item: education,
                      show: true,
                    });
                  }}
                >
                  <CreateIcon />
                </StyledRowContainerAdd>
                <StyledContainerXP>
                  <CardItem
                    variant={'blue'}
                    size={'medium'}
                    emoji={<SingleLineText text={education?.name?.substring(0, 1)} />}
                  />
                  <StyledContainerXPDetails>
                    <StyledTitles variant={'subtitle2Medium'} text={education.label} />
                    <StyledTitles variant={'subtitle2Medium'} text={education?.name} />
                    <StyledDateText
                      variant={'subtitle1'}
                      text={constructDateDetails(education.period, education?.current)}
                    />
                    <SingleLineText variant={'body1Light'} text={education?.description} />
                  </StyledContainerXPDetails>
                </StyledContainerXP>
                {educations?.length - 1 !== i && <StyledBarXP></StyledBarXP>}
              </div>
            );
          })
        ) : (
          <StyledEmptyState>
            <EmptyState image={empty} text={t('noEducation')} />
          </StyledEmptyState>
        )}
      </StyledXP>
    </div>
  );
};

export default TalentSheetFormation;
export { TalentSheetFormation };

const StyledEmptyState = styled('div')(
  () => `
  display: flex;
  justify-content: center;
  padding-top: 24px;
  `,
);

const StyledBarXP = styled('div')(
  ({ theme }) => `
  height: 0px;
  margin-top: 32px;
  border: 1px solid ${theme.palette.grey[400]};
  `,
);

const StyledXP = styled('div')(
  () => `
  box-shadow: 0px 4.30769px 23.6923px rgba(75, 75, 75, 0.09);
  border-radius: 10px;
  text-align: left;
  padding: 24px 24px 32px 24px;
  margin-bottom: 24px;
  `,
);

const StyledContainerXPDetails = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
  max-width: 1280px;
  gap: 8px;
  `,
);

const StyledContainerXP = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  align-items: flex-start;
  margin-top: 32px;
  `,
);

const StyledDateText = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[800]};
  `,
);

const StyledTitles = styled(SingleLineText)(
  ({ theme }) => `
  color: ${theme.palette.grey[100]};
  `,
);

const StyledRowContainerAdd = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  text-decoration: underline;
  color: ${theme.palette.grey[100]};
  float: right;

  &:hover {
    cursor: pointer;
  }
  `,
);
