import AvatarUsername from '../molecules/AvatarUsername';
import { SingleLineText, TypeInput } from '@bloomays-lib/ui.shared';
import {
  convertDateToStringFormat,
  mailRegex,
  phoneRegex,
  removePointsAndSpaces,
  nameRegex,
  testGHUrl,
  testLKUrl,
  testTurnOverITUrl,
  getProfileByNetwork,
  mergeProfilesByNetwork,
  testHelloWorkUrl,
} from '@bloomays-lib/utils.shared';
import { styled } from '@mui/material/styles';
import {
  IJobTitle,
  Location,
  ITalent,
  Profile,
  RemoteWork,
  Status,
  BloomerStatus,
  ProfileNetwork,
  ITalentBasicInput,
  IBasics,
} from '@bloomays-lib/types.shared';
import { useTranslation } from 'react-i18next';
import { Logo } from '@bloomays-lib/ui.shared';
import { ValidationTextField } from '@bloomays-lib/ui.shared';
import React, { useEffect, useState } from 'react';
import { Button } from '@bloomays-lib/ui.shared';
import { notify } from '../../helpers/toastify';
import plus from '../../graphic-charter/icons/plus.png';
import { SelectField } from '../atoms/SelectField';
import SelectReasearch from '../molecules/SelectResearch';
import DeleteTalentPopin from './DeleteTalentPopin';
import isUndefined from 'lodash/fp/isUndefined';

export const getStatus = (status: Status) => {
  switch (status) {
    case Status.CDI:
      return 'CDI';
    case Status.Freelance:
      return 'Freelance';
    case Status.FreeAndCDI:
      return 'Freelance, CDI';
    default:
      return '';
  }
};

export const getRemoteWork = (work: RemoteWork) => {
  switch (work) {
    case RemoteWork.Flexible:
      return 'Flexible';
    case RemoteWork.OnSite:
      return 'Présentiel';
    case RemoteWork.Remote:
      return 'Télétravail';
    default:
      return '';
  }
};

export type TalentSheetSideBarProps = {
  talent?: ITalent;
  origin: 'searchPage' | 'talentPage';
  loadingBasic: boolean;
  jobTitleList?: IJobTitle[];
  onSearch: (e: any) => void;
  loadingLocation: boolean;
  locationsState?: Location[];
  setLocationsState: React.Dispatch<React.SetStateAction<Location[] | undefined>>;
  upsertBasic: (talent: ITalentBasicInput) => Promise<ITalent | undefined>;
};

// Ce component partage une bonne partie avec TalentForm sur crx.talent-finder. Ils doivent être refactorés !!
const TalentSheetSideBar = ({
  talent,
  upsertBasic,
  loadingBasic,
  jobTitleList,
  onSearch,
  loadingLocation,
  locationsState,
  setLocationsState,
  origin,
}: TalentSheetSideBarProps) => {
  const { t } = useTranslation(['talentSheet', 'random']);
  const [basicState, setBasicState] = useState<IBasics>({ firstname: '', lastname: '', jobTitle: '' });
  const [hasChanged, setHasChanged] = useState(false);
  const [openDeleteTalentPopin, setOpenDeleteTalentPopin] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const [profiles, setProfiles] = useState<Profile[]>(talent?.profiles || []);

  const [helperTextFirstname, setHelperTextFirstname] = useState();
  const [helperTextLastname, setHelperTextLastname] = useState();
  const [helperTextMail, setHelperTextMail] = useState();
  const [helperTextMails, setHelperTextMails] = useState();
  const [helperTextPhone, setHelperTextPhone] = useState();
  const [helperTextPhones, setHelperTextPhones] = useState();
  const [helperTextLK, setHelperTextLK] = useState();
  const [helperTextGH, setHelperTextGH] = useState();
  const [helperTextTOIT, setHelperTextTOIT] = useState();
  const [helperTextHW, setHelperTextHW] = useState();

  useEffect(() => {
    if (talent) {
      const basics: IBasics = {
        firstname: talent.firstname,
        lastname: talent.lastname,
        email: talent.email,
        positionTitle: talent.positionTitle,
        secondaryEmails: talent?.secondaryEmails,
        phone: talent.phone,
        secondaryPhones: talent.secondaryPhones,
        remoteWork: talent.remoteWork,
        status: talent.status,
        bloomerStatus: talent.bloomerStatus,
        tjm: talent.tjm,
        package: talent.package,
        profiles: talent?.profiles,
        location: talent?.location,
        jobTitle: talent.positionTitle?.jobTitle.label || talent.jobTitle,
      };
      setBasicState({ ...basics });
      setProfiles(talent?.profiles || []);
    }
  }, [talent]);

  const checkValue = (value: string, type: string) => {
    switch (type) {
      case 'firstname':
        if (value.length < 2 || !nameRegex.test(value)) {
          setHelperTextFirstname(t('minCharat', { ns: 'talentSheet' }));
        } else {
          setHelperTextFirstname(undefined);
        }
        break;
      case 'lastname':
        if (value?.length < 2 || !nameRegex.test(value)) {
          setHelperTextLastname(t('minCharat', { ns: 'talentSheet' }));
        } else {
          setHelperTextLastname(undefined);
        }
        break;
      case 'email':
        if (value !== '' && !mailRegex.test(value)) {
          setHelperTextMail(t('emailNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextMail(undefined);
        }
        break;
      case 'emails':
        if (!mailRegex.test(value) && value?.length !== 0) {
          setHelperTextMails(t('emailNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextMails(undefined);
        }
        break;
      case 'phone':
        if (!phoneRegex.test(value) && value?.length !== 0) {
          setHelperTextPhone(t('phoneNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextPhone(undefined);
        }
        break;
      case 'phones':
        if (!phoneRegex.test(value) && value?.length !== 0) {
          setHelperTextPhones(t('phoneNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextPhones(undefined);
        }
        break;
      case 'linkedin':
        if (!testLKUrl(value) && value?.length !== 0) {
          setHelperTextLK(t('LKNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextLK(undefined);
        }
        break;
      case 'github':
        if (!testGHUrl(value) && value?.length !== 0) {
          setHelperTextGH(t('GHNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextGH(undefined);
        }
        break;
      case 'turnoverit':
        if (!testTurnOverITUrl(value) && value?.length !== 0) {
          setHelperTextTOIT(t('TOTINotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextTOIT(undefined);
        }
        break;
      case 'helloWork':
        if (!testHelloWorkUrl(value) && value?.length !== 0) {
          setHelperTextHW(t('HWNotValid', { ns: 'talentSheet' }));
        } else {
          setHelperTextHW(undefined);
        }
        break;
    }
  };

  const updateTalent = (newTalent: any) => {
    setBasicState(newTalent);
    setHasChanged(true);
  };

  const updateSecondaryEmailTalent = (e: any) => {
    const email = e.target.value.trim();

    checkValue(email, 'emails');
    updateTalent({
      ...basicState,
      secondaryEmails: [email],
    });
  };

  const updatePhoneTalent = (e: any) => {
    const phone = e.target.value;

    checkValue(phone, 'phones');
    updateTalent({
      ...basicState,
      secondaryPhones: [phone],
    });
  };

  const createRowMailPhone = (keyName: 'secondaryEmails' | 'secondaryPhones') => {
    let arrayTMP = basicState?.[keyName]?.slice() || [];
    arrayTMP = [...arrayTMP, ''];
    updateTalent({
      ...basicState,
      [keyName]: arrayTMP,
    });
  };

  const optionsPositionTitle =
    jobTitleList?.map((item) => {
      return { value: item.label, label: item.label };
    }) || [];

  const disabledButton: boolean =
    !!loadingBasic ||
    !!helperTextFirstname ||
    !!helperTextLastname ||
    !!helperTextPhone ||
    !!helperTextMail ||
    !!helperTextLK ||
    !!helperTextGH ||
    !!helperTextMails ||
    !!helperTextPhones ||
    !hasChanged;

  return (
    <StyledLeftContainer>
      {talent ? (
        <DeleteTalentPopin
          talent={talent}
          opened={openDeleteTalentPopin}
          openPopin={setOpenDeleteTalentPopin}
          origin={origin}
        />
      ) : null}
      <StyledRowAvatarContainer>
        <AvatarUsername name={`${talent?.firstname} ${talent?.lastname}`} image={talent?.image} size={'large'} />
      </StyledRowAvatarContainer>
      <StyledLeftContainerDetails>
        <StyledDetailContainer>
          <StyledRowContainer>
            <SingleLineText variant={'body2Medium'} text={t('details')} />
          </StyledRowContainer>
          <StyledRowContainer>
            <ValidationTextField
              label={t('firstname', { ns: 'talentSheet' })}
              onChange={(e: any) => {
                checkValue(e.target.value, 'firstname');
                setBasicState({ ...basicState, firstname: e.target.value } as IBasics);
                setHasChanged(true);
              }}
              value={basicState?.firstname || ''}
              size={'small'}
              placeholder={''}
              type={TypeInput.text}
              helperText={helperTextFirstname}
              error={!isUndefined(helperTextFirstname)}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <ValidationTextField
              label={t('lastname', { ns: 'talentSheet' })}
              onChange={(e: any) => {
                checkValue(e.target.value, 'lastname');
                setBasicState({ ...basicState, lastname: e.target.value });
                setHasChanged(true);
              }}
              value={basicState.lastname || ''}
              size={'small'}
              helperText={helperTextLastname}
              error={!isUndefined(helperTextLastname)}
              placeholder={t('lastname', { ns: 'talentSheet' })}
              type={TypeInput.text}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <SelectField
              size={'small'}
              defaultOption={true}
              optionValue={[Status.CDI, Status.Freelance, 'Free et CDI']}
              handlechange={(e: any) =>
                updateTalent({
                  ...basicState,
                  status: e.target.value === 'Free et CDI' ? Status.FreeAndCDI : e.target.value,
                })
              }
              value={[basicState?.status === Status.FreeAndCDI ? 'Free et CDI' : basicState?.status || '']}
              name={'status'}
              label={t('status', { ns: 'talentSheet' })}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <ValidationTextField
              label={t('jobTitle')}
              onChange={(e: any) => {
                updateTalent({
                  ...basicState,
                  positionTitle: {
                    id: talent?.positionTitle?.id,
                    jobTitle: { label: e.target.value },
                    talentId: talent?.id,
                  },
                });
              }}
              required
              value={basicState?.positionTitle ? basicState?.positionTitle.jobTitle.label : '-'}
              size={'small'}
              type={TypeInput.select}
              placeholder={''}
              options={[
                ...optionsPositionTitle,
                {
                  value: basicState?.positionTitle?.jobTitle.label || '-',
                  label: basicState?.positionTitle?.jobTitle.label || '-',
                },
              ]}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <SelectReasearch
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              loading={loadingLocation}
              allItems={locationsState}
              setAllItems={setLocationsState}
              onSearch={onSearch}
              existingLocation={basicState.location}
              value={
                basicState?.location?.address
                  ? `${basicState?.location?.address}${
                      basicState?.location?.range ? ` (${basicState?.location?.range}km)` : ''
                    }`
                  : ''
              }
              validateLocation={(e) => {
                updateTalent({
                  ...basicState,
                  location: { ...e, id: talent?.location?.id },
                });
              }}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <SelectField
              defaultOption={true}
              multiple={false}
              optionValue={[RemoteWork.Flexible, RemoteWork.Remote, RemoteWork.OnSite]}
              handlechange={(e: any) => updateTalent({ ...basicState, remoteWork: e.target.value })}
              value={[basicState.remoteWork || '']}
              name={'remoteWork'}
              size={'small'}
              label={t('remoteWork', { ns: 'talentSheet' })}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <ValidationTextField
              label={t('availabilityDate')}
              onChange={(e: any) => undefined}
              value={talent?.availabilityDate ? convertDateToStringFormat(talent?.availabilityDate, 'dd/MM/yyyy') : '-'}
              size={'small'}
              placeholder={''}
              disabled={true}
            />
          </StyledRowContainer>
          {(basicState.status === Status.Freelance || basicState.status === Status.FreeAndCDI) && (
            <StyledRowContainer>
              <ValidationTextField
                minLength="0"
                label={'TJM €'}
                type={TypeInput.number}
                onChange={(e: any) => {
                  checkValue(e.target.value, 'TJM');
                  setBasicState({
                    ...basicState,
                    tjm: Number(e.target.value),
                  });
                  setHasChanged(true);
                }}
                value={basicState?.tjm || ''}
                size={'small'}
                placeholder={''}
              />
            </StyledRowContainer>
          )}
          {(basicState.status === Status.CDI || basicState.status === Status.FreeAndCDI) && (
            <StyledRowContainer>
              <ValidationTextField
                label={`${t('package', { ns: 'talentSheet' })} €`}
                type={TypeInput.number}
                onChange={(e: any) => {
                  checkValue(e.target.value, 'package');
                  setBasicState({
                    ...basicState,
                    package: Number(e.target.value),
                  });
                  setHasChanged(true);
                }}
                value={basicState?.package || ''}
                size={'small'}
                placeholder={''}
              />
            </StyledRowContainer>
          )}
        </StyledDetailContainer>
        <StyledBar></StyledBar>
        <StyledDetailContainer>
          <StyledRowContainer>
            <SingleLineText variant={'body2Medium'} text={t('contact')} />
          </StyledRowContainer>
          <StyledRowContainer>
            <ValidationTextField
              type={TypeInput.email}
              aria-label="talent-email"
              label={t('email', { ns: 'talentSheet' })}
              onChange={(e: any) => {
                checkValue(e.target.value.trim(), 'email');
                setBasicState({ ...basicState, email: e.target.value.trim() });
                setHasChanged(true);
              }}
              value={basicState?.email || ''}
              size={'small'}
              placeholder={t('email', { ns: 'talentSheet' })}
              helperText={helperTextMail}
              error={!isUndefined(helperTextMail)}
            />
          </StyledRowContainer>
          {basicState?.secondaryEmails && basicState?.secondaryEmails?.length >= 1 ? (
            ''
          ) : (
            <StyledRowContainerAdd
              onClick={() => {
                createRowMailPhone('secondaryEmails');
              }}
            >
              <StyledAddLogo image={plus} />
              <SingleLineText text={t('addEmail', { ns: 'talentSheet' })} />
            </StyledRowContainerAdd>
          )}
          {basicState?.secondaryEmails?.map((mail, key) => {
            return (
              <StyledRowContainer key={`secondaryEmail-${key}`}>
                <ValidationTextField
                  type={TypeInput.email}
                  label={t('secondaryEmails', { ns: 'talentSheet' })}
                  onChange={(e: { target: { value: string } }) => {
                    updateSecondaryEmailTalent(e);
                  }}
                  value={mail || ''}
                  size={'small'}
                  placeholder={t('secondaryEmails', { ns: 'talentSheet' })}
                  helperText={helperTextMails}
                  error={!isUndefined(helperTextMails)}
                />
              </StyledRowContainer>
            );
          })}
          <StyledRowContainer>
            <ValidationTextField
              type={TypeInput.tel}
              label={t('phone', { ns: 'talentSheet' })}
              onChange={(e: any) => {
                const phoneCleaned = removePointsAndSpaces(e.target.value);
                checkValue(phoneCleaned, 'phone');
                setBasicState({ ...basicState, phone: phoneCleaned });
                setHasChanged(true);
              }}
              value={basicState?.phone || ''}
              size={'small'}
              placeholder={t('phone', { ns: 'talentSheet' })}
              helperText={helperTextPhone}
              error={!isUndefined(helperTextPhone)}
            />
          </StyledRowContainer>
          {basicState?.secondaryPhones && basicState?.secondaryPhones?.length >= 1 ? (
            ''
          ) : (
            <StyledRowContainerAdd
              onClick={() => {
                createRowMailPhone('secondaryPhones');
              }}
            >
              <StyledAddLogo image={plus} />
              <SingleLineText text={t('addPhone', { ns: 'talentSheet' })} />
            </StyledRowContainerAdd>
          )}
          {basicState?.secondaryPhones?.map((phone) => {
            return (
              <StyledRowContainer>
                <ValidationTextField
                  type={TypeInput.tel}
                  label={t('secondaryPhones', { ns: 'talentSheet' })}
                  onChange={(e: { target: { value: string } }) => {
                    updatePhoneTalent(e);
                  }}
                  value={phone || ''}
                  size={'small'}
                  placeholder={t('secondaryPhones', { ns: 'talentSheet' })}
                  helperText={helperTextPhones}
                  error={!isUndefined(helperTextPhones)}
                />
              </StyledRowContainer>
            );
          })}
          <ValidationTextField
            type={TypeInput.url}
            label={t('LK', { ns: 'talentSheet' })}
            onChange={(e: any) => {
              checkValue(e.target.value, 'linkedin');
              const newProfile: Profile = {
                url: e.target.value,
                network: ProfileNetwork.LinkedIn,
              };
              setProfiles(mergeProfilesByNetwork(profiles, newProfile));
              setHasChanged(true);
            }}
            value={getProfileByNetwork(profiles, ProfileNetwork.LinkedIn)?.url || ''}
            size={'small'}
            placeholder={''}
            helperText={helperTextLK}
            error={!isUndefined(helperTextLK)}
          />

          <ValidationTextField
            type={TypeInput.url}
            label={t('GH', { ns: 'talentSheet' })}
            onChange={(e: any) => {
              checkValue(e.target.value, 'github');
              const newProfile: Profile = {
                url: e.target.value,
                network: ProfileNetwork.Github,
              };
              setProfiles(mergeProfilesByNetwork(profiles, newProfile));
              setHasChanged(true);
            }}
            value={getProfileByNetwork(profiles, ProfileNetwork.Github)?.url || ''}
            size={'small'}
            placeholder={''}
            helperText={helperTextGH}
            error={!isUndefined(helperTextGH)}
          />
          <ValidationTextField
            type={TypeInput.url}
            label={t('TOIT', { ns: 'talentSheet' })}
            onChange={(e: any) => {
              checkValue(e.target.value, 'turnoverit');
              const newProfile: Profile = {
                url: e.target.value,
                network: ProfileNetwork.TurnoverIT,
              };
              setProfiles(mergeProfilesByNetwork(profiles, newProfile));
              setHasChanged(true);
            }}
            value={getProfileByNetwork(profiles, ProfileNetwork.TurnoverIT)?.url || ''}
            size={'small'}
            placeholder={''}
            helperText={helperTextTOIT}
            error={!isUndefined(helperTextTOIT)}
          />
          <ValidationTextField
            type={TypeInput.url}
            label={t('HW', { ns: 'talentSheet' })}
            onChange={(e: any) => {
              checkValue(e.target.value, 'helloWork');
              const newProfile: Profile = {
                url: e.target.value,
                network: ProfileNetwork.HelloWork,
              };
              setProfiles(mergeProfilesByNetwork(profiles, newProfile));
              setHasChanged(true);
            }}
            value={getProfileByNetwork(profiles, ProfileNetwork.HelloWork)?.url || ''}
            size={'small'}
            placeholder={''}
            helperText={helperTextHW}
            error={!isUndefined(helperTextHW)}
          />
        </StyledDetailContainer>
        <StyledBar></StyledBar>
        <StyledRowContainer>
          <SelectField
            defaultOption={true}
            optionValue={[BloomerStatus.Bloomer, BloomerStatus.SuperBloomer]}
            handlechange={(e: any) => {
              updateTalent({
                ...basicState,
                bloomerStatus: e.target.value ? e.target.value : null,
              });
            }}
            value={[basicState?.bloomerStatus || '']}
            name={'bloomerAndSuperBloomerSelect'}
            label={'Bloomer'}
            size={'small'}
          />
        </StyledRowContainer>
        <StyledRowContainer>
          <ValidationTextField
            label={t('owner')}
            type={TypeInput.text}
            onChange={(e: any) => undefined}
            value={talent?.owner ? talent?.owner : '-'}
            size={'small'}
            placeholder={''}
            disabled={true}
          />
        </StyledRowContainer>
        <StyledRowContainer>
          <ValidationTextField
            label={t('origin')}
            type={TypeInput.text}
            onChange={(e: any) => undefined}
            value={talent?.origin ? talent?.origin : '-'}
            size={'small'}
            placeholder={''}
            disabled={true}
          />
        </StyledRowContainer>
        <StyledButtonContainer>
          <Button
            // eslint-disable-next-line jsx-a11y/aria-role
            role="save-talent"
            onClick={async () => {
              try {
                const network = profiles.filter((net) => net !== undefined);
                const email = basicState.email || null;
                const basic = await upsertBasic({
                  id: talent?.id,
                  basics: {
                    ...basicState,
                    email: email ?? undefined,
                    profiles: network,
                    phone: basicState?.phone && basicState?.phone !== '' ? basicState?.phone : undefined,
                    secondaryEmails: basicState.secondaryEmails?.filter((mail) => mail !== ''),
                    secondaryPhones: basicState.secondaryPhones?.filter((phone) => phone !== ''),
                  },
                });
                setHasChanged(false);
                if (basic) {
                  notify('success', t('modifSaved', { ns: 'random' }), null, {
                    hideProgressBar: true,
                    autoClose: 10000,
                  });
                }
              } catch (err) {
                notify('error', t('updateError', { ns: 'random' }), err, {
                  hideProgressBar: true,
                  autoClose: 10000,
                });
              }
            }}
            textButton={t('save', { ns: 'random' })}
            disable={disabledButton}
          />
        </StyledButtonContainer>
        {talent ? (
          <>
            <StyledBar></StyledBar>
            <StyleDangerZone>
              <StyledRowContainer>
                <SingleLineText variant={'body2Medium'} text={t('dangerZone', { ns: 'random' })} />
              </StyledRowContainer>
              <StyledButtonContainer>
                <Button
                  onClick={() => {
                    setOpenDeleteTalentPopin(true);
                  }}
                  textButton={t('delete', { ns: 'random' })}
                />
              </StyledButtonContainer>
            </StyleDangerZone>
          </>
        ) : null}
      </StyledLeftContainerDetails>
    </StyledLeftContainer>
  );
};

export default TalentSheetSideBar;
export { TalentSheetSideBar };

const StyledAddLogo = styled(Logo)(
  () => `
  padding-right: 0.3em;
  `,
);

const StyledLeftContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  min-width: 386px;
  max-width: 386px;
  background-color: ${theme.palette.grey[200]};
`,
);

const StyledLeftContainerDetails = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: column nowrap;
  background-color: ${theme.palette.grey[200]};
  padding: 16px 24px;
  gap: 16px;
`,
);

const StyledRowContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  `,
);

const StyledButtonContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px;
  `,
);

const StyledRowContainerAdd = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  color: ${theme.palette.grey[800]};

  &:hover {
    cursor: pointer;
  }
  `,
);

const StyledRowAvatarContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: row nowrap;
  padding-top: 64px;
  padding-left: 14px;
  `,
);

const StyledDetailContainer = styled('div')(
  () => `
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  gap: 10px;
  `,
);

const StyledBar = styled('div')(
  ({ theme }) => `
  height: 0px;
  border: 1px solid ${theme.palette.grey[400]};
  `,
);

const StyleDangerZone = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.palette.tertiary.redLight};
    padding-left: 10px;
    padding-right: 10px;
  `,
);
