import { Theme, styled } from '@mui/material/styles';
import { Upload } from './Upload';
import { useMutation } from '@apollo/client';
import { BrowserBloomer, BrowserUploadFile } from '@bloomays-lib/types.shared';
import { GET_ONE_FULL_MISSION, UPSERT_BLOOMER } from '@bloomays-lib/adapter.api-bloomer';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mailRegex, nameRegex, phoneRegex } from '@bloomays-lib/utils.shared';
import isEmpty from 'lodash/fp/isEmpty';
import isUndefined from 'lodash/fp/isUndefined';
import { Button, LoaderPending, TypeInput, ValidationTextField } from '@bloomays-lib/ui.shared';
import { notify } from '../../helpers/toastify';
import { useTranslation } from 'react-i18next';
import { FileRejection } from 'react-dropzone';
import RawPermIdentityIcon from '@mui/icons-material/PermIdentity';
import RawMailOutlineIcon from '@mui/icons-material/MailOutline';
import RawPhoneIcon from '@mui/icons-material/Phone';

type UpdateBloomerFormProps = {
  callback?: () => void;
  bloomer: BrowserBloomer;
};

export const UpdateBloomerForm = ({ callback, bloomer }: UpdateBloomerFormProps) => {
  const [files, setFiles] = useState<BrowserUploadFile[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const [helperTextFirstname, setHelperTextFirstname] = useState<string | undefined>();
  const [helperTextLastname, setHelperTextLastname] = useState<string | undefined>();
  const [helperTextMail, setHelperTextMail] = useState<string | undefined>();
  const [helperTextSecondaryMail, setHelperTextSecondaryMail] = useState<string | undefined>();
  const [helperTextPhone, setHelperTextPhone] = useState<string | undefined>();

  const { t } = useTranslation(['notify']);
  const param = useParams<{ id: string }>();

  const [upsertBloomer, { loading }] = useMutation<{ upsertBloomer: BrowserBloomer }>(UPSERT_BLOOMER, {
    refetchQueries: [
      {
        query: GET_ONE_FULL_MISSION,
        variables: {
          recordId: param.id,
        },
      },
    ],
  });

  const checkValue = (value: string, type: string) => {
    switch (type) {
      case 'firstname':
        if (value?.length < 1 || !nameRegex.test(value)) {
          setHelperTextFirstname("Min 1 lettres, pas d'emoji, ni caractères spéciaux");
        } else {
          setHelperTextFirstname(undefined);
        }
        break;
      case 'lastname':
        if (value?.length < 1 || !nameRegex.test(value)) {
          setHelperTextLastname("Min 1 lettres, pas d'emoji, ni caractères spéciaux");
        } else {
          setHelperTextLastname(undefined);
        }
        break;
      case 'email':
        if (value !== '' && !mailRegex.test(value)) {
          setHelperTextMail("Cet email n'a pas le format requis : xxxx@xx.xx");
        } else {
          setHelperTextMail(undefined);
        }
        break;
      case 'secondaryEmail':
        if (value !== '' && !mailRegex.test(value)) {
          setHelperTextSecondaryMail("Cet email n'a pas le format requis : xxxx@xx.xx");
        } else {
          setHelperTextSecondaryMail(undefined);
        }
        break;
      case 'phoneNumber':
        if (!phoneRegex.test(value) && value?.length !== 0) {
          setHelperTextPhone("Ce numéro n'a pas le format requis : xxxxxxxxxx ou +xxxxxxxxxxx");
        } else {
          setHelperTextPhone(undefined);
        }
        break;
    }
  };

  const submitBloomerUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.currentTarget);

      if (isError) return;

      const result = await upsertBloomer({
        variables: {
          input: {
            recordId: bloomer.recordId,
            firstname: formData.get('firstname'),
            lastname: formData.get('lastname'),
            email: formData.get('email'),
            secondaryEmail: formData.get('secondaryEmail'),
            phoneNumber: formData.get('phoneNumber'),
            credentialID: files[0],
          },
        },
      });

      callback && callback();
      notify('success', t('updateSuccess', { ns: 'notify' }), null, {
        hideProgressBar: false,
        autoClose: 1000,
      });

      return result.data?.upsertBloomer;
    } catch (e) {
      notify('error', t('randomUpdateError', { ns: 'notify' }), null, {
        hideProgressBar: true,
        autoClose: 1000,
      });
    }
  };

  const isError =
    !isEmpty(helperTextFirstname) ||
    !isEmpty(helperTextLastname) ||
    !isEmpty(helperTextMail) ||
    !isEmpty(helperTextSecondaryMail) ||
    !isEmpty(helperTextPhone) ||
    rejectedFiles.length > 0;

  return (
    <Form onSubmit={submitBloomerUpdate}>
      {loading && <LoaderPending text={t('saveLoading', { ns: 'random' })} />}
      <FormTitle>Le Bloomer</FormTitle>
      <InputContainer>
        <PermIdentityIcon sx={{ width: '30px' }} />
        <ValidationTextField
          name="firstname"
          id="firstname"
          label="Prénom"
          value={bloomer?.firstname}
          type={TypeInput.text}
          size={'small'}
          error={!isUndefined(helperTextFirstname)}
          helperText={helperTextFirstname}
          required={true}
          onChange={(e) => {
            checkValue(e.target.value, 'firstname');
          }}
        />
      </InputContainer>
      <InputContainer>
        <PermIdentityIcon sx={{ width: '30px' }} />
        <ValidationTextField
          name="lastname"
          id="lastname"
          label="Nom"
          value={bloomer?.lastname}
          type={TypeInput.text}
          size={'small'}
          error={!isUndefined(helperTextLastname)}
          helperText={helperTextLastname}
          required={true}
          onChange={(e) => {
            checkValue(e.target.value, 'lastname');
          }}
        />
      </InputContainer>
      <InputContainer>
        <MailOutlineIcon sx={{ width: '30px' }} />
        <ValidationTextField
          name="email"
          id="email"
          label="Email"
          value={bloomer?.email}
          type={TypeInput.email}
          size={'small'}
          error={!isUndefined(helperTextMail)}
          helperText={helperTextMail}
          onChange={(e) => {
            checkValue(e.target.value, 'email');
          }}
        />
      </InputContainer>
      <InputContainer>
        <MailOutlineIcon sx={{ width: '30px' }} />
        <ValidationTextField
          name="secondaryEmail"
          id="secondaryEmail"
          label="Email secondaire"
          value={bloomer?.secondaryEmail}
          type={TypeInput.email}
          size={'small'}
          error={!isUndefined(helperTextSecondaryMail)}
          helperText={helperTextSecondaryMail}
          onChange={(e) => {
            checkValue(e.target.value, 'secondaryEmail');
          }}
        />
      </InputContainer>
      <InputContainer>
        <PhoneIcon sx={{ width: '30px' }} />
        <ValidationTextField
          name="phoneNumber"
          id="phoneNumber"
          label="Téléphone"
          value={bloomer?.phoneNumber}
          type={TypeInput.tel}
          size={'small'}
          error={!isUndefined(helperTextPhone)}
          helperText={helperTextPhone}
          onChange={(e) => {
            checkValue(e.target.value, 'phoneNumber');
          }}
        />
      </InputContainer>
      <InputContainer>
        <PermIdentityIcon sx={{ width: '30px' }} />
        <Upload
          aria-label="personnalinfos-credentialid"
          id="credentialId"
          placeholder="Pièce d'identité"
          textLabel="Ajouter une pièce d'identité"
          name="credentialId"
          fileTypes={['.pdf']}
          required={true}
          maxFiles={1}
          maxLength={5000000}
          preloadedFiles={[]}
          autoload={true}
          displayIcons={true}
          handleChange={(files, reject) => {
            setRejectedFiles(reject);
            setFiles(files);
          }}
          sx={{ margin: 0, marginBottom: 3 }}
        />
      </InputContainer>

      <ButtonsContainer>
        {callback && (
          <Button
            color="secondary"
            textButton="Annuler"
            onClick={() => {
              callback();
            }}
          />
        )}
        <Button textButton="Valider" buttonType="submit" disable={isError} />
      </ButtonsContainer>
    </Form>
  );
};

const FormTitle = styled('h2')(
  () => `
        text-align: left;
        margin-bottom: 10px;
      `,
);

const InputContainer = styled('div')(
  () => `
        display: flex;
      `,
);

const Form = styled('form')(
  () => `
        display: flex;
        flex-direction: column;
    `,
);

const ButtonsContainer = styled('div')(
  () => `
        display: flex;
        width: 100%;
        justify-content: space-between;
    `,
);

const iconCSS = (args: { theme: Theme }) => ({
  paddingRight: 10,
  marginTop: 12,
  color: args.theme.palette.grey[500],
  width: 20,
});

const PermIdentityIcon = styled(RawPermIdentityIcon)(iconCSS);
const MailOutlineIcon = styled(RawMailOutlineIcon)(iconCSS);
const PhoneIcon = styled(RawPhoneIcon)(iconCSS);
