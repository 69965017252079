import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ContextMissionDetails from '../organisms/ContextMissionDetails';
import WithErrorBoundary from '../organisms/ErrorBoundary';
import {
  BrowserBloomer,
  IContact,
  IMissionCardDetails,
  ISociety,
  GetOneFullMissionResponse,
  GetOneFullMissionParams,
} from '@bloomays-lib/types.shared';
import { GET_ONE_FULL_MISSION } from '@bloomays-lib/adapter.api-bloomer';
import { errorLogger } from '../../helpers/error';
import { getFirstValidPrice, greaterDate } from '@bloomays-lib/utils.shared';
import { notify } from '../../helpers/toastify';
import { LoaderPending } from '@bloomays-lib/ui.shared';
import { useTranslation } from 'react-i18next';

interface FormData {
  bloomer?: BrowserBloomer;
  clientSociety?: ISociety;
  clientBillingSociety?: ISociety;
  contextContactBillingClient?: IContact | IContact[];
  bloomerSociety?: ISociety;
  contactBloomerSociety?: IContact;
  contactOperations?: IContact;
  mission?: IMissionCardDetails;
}

const MissionDetails = () => {
  const { t } = useTranslation(['random']);
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error('Missing ID params');
  }

  const {
    loading,
    error,
    data: dataMission,
  } = useQuery<GetOneFullMissionResponse, GetOneFullMissionParams>(GET_ONE_FULL_MISSION, {
    variables: {
      recordId: id,
    },
  });

  const [data, setData] = useState<FormData>();

  React.useEffect(() => {
    if (!dataMission) return;
    const mission = dataMission?.missions?.[0];
    if (!mission) return;
    const missionCardDetails = { ...mission, isDeal: true } as unknown as IMissionCardDetails;

    const activePricing = getFirstValidPrice(
      [...(mission.pricing || [])],
      greaterDate(new Date(mission.beginMission as string), new Date()),
    );
    missionCardDetails.purchasePricePerDayWithoutTaxes = activePricing?.purchasePricePerDayWithoutTaxes;
    missionCardDetails.salePricePerDayWithoutTaxes = activePricing?.salePricePerDayWithoutTaxes;
    missionCardDetails.taxes = activePricing?.taxes;
    missionCardDetails.CSM = mission.CSM?.fullname || 'Non attribué';
    missionCardDetails.prices = mission.pricing?.map((price) => ({
      ...price,
      startDate: price.startDate ? new Date(price.startDate) : undefined,
    }));
    missionCardDetails.hoursPerDays = mission.hoursPerDays;

    setData((prevData) => ({
      ...prevData,
      bloomer: mission?.bloomer,
      clientSociety: mission?.clientSociety,
      clientBillingSociety: mission?.clientBillingSociety,
      contextContactBillingClient: mission?.billingContacts || mission?.clientSociety?.billingContact,
      bloomerSociety: mission?.bloomerBillingSociety?.[0],
      contactBloomerSociety: mission?.bloomerBillingSociety?.[0]?.billingContact,
      contactOperations: mission?.contactOperations,
      mission: missionCardDetails,
    }));
  }, [dataMission]);

  React.useEffect(() => {
    if (error) {
      errorLogger(error, {
        extraInfos: 'Internal server error, impossible to retrieve data about contact bloomer / mission !',
        state: {},
      });
      notify(
        'error',
        'Impossible de récupérer les données de mission. Erreur du server, essaie plus tard ! 😓 ',
        error,
        undefined,
      );
    }
  }, [error]);

  if (loading || !data?.mission?.recordId) return <LoaderPending text={t('loadingText')} />;

  return (
    <ContextMissionDetails
      contextBloomer={data.bloomer as BrowserBloomer}
      contextMission={data.mission}
      contextContactBloomer={data.contactBloomerSociety as IContact}
      contextContactBillingClient={data.contextContactBillingClient as IContact | IContact[]}
      contextSocietyBloomer={data.bloomerSociety as ISociety}
      contextSocietyClient={data.clientSociety as ISociety}
      contextSocietyClientBilling={(data.clientBillingSociety as ISociety) || (data.clientSociety as ISociety)}
      contextContactOperationnal={data.contactOperations as IContact}
      error={error}
    />
  );
};

export default WithErrorBoundary(MissionDetails);
