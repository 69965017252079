import React, { useEffect, useRef, useState } from 'react';

import { EditBloomerSociety } from './EditBloomerSociety';
import { EditPersonnalInfos } from './EditPersonnalInfos';
import { Button } from '@bloomays-lib/ui.shared';
import { BrowserBloomer, BrowserMe, ISociety } from '@bloomays-lib/types.shared';

export type InscriptionBloomerProps = {
  onBloomerDataValidated: (data: BrowserBloomer) => Promise<BrowserBloomer | undefined>;
  onSocietyDataValidated: (data: ISociety) => Promise<ISociety | undefined>;
  me: BrowserMe;
  uploading: boolean;
};

const InscriptionBloomer = ({
  onBloomerDataValidated,
  onSocietyDataValidated,
  me,
  uploading = false,
}: InscriptionBloomerProps) => {
  const billingSociety = me?.bloomer?.mission
    ?.findLast((m) => (m.bloomerBillingSociety || []).length > 0)
    ?.bloomerBillingSociety?.findLast(() => true);
  const formPersonnal = useRef<any>();
  const formSociety = useRef<any>();
  const [enabled, setEnabled] = useState(!uploading);

  useEffect(() => {
    setEnabled(!uploading);
  }, [uploading]);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    // setTimeout to immeditaly disable the button
    setTimeout(() => setEnabled(false), 0);
    e.preventDefault();
    let validated = await formPersonnal?.current?.validate(e);
    if (!validated) {
      // must be set in a setTimeout to avoid a race condition
      setTimeout(() => setEnabled(true), 0);
      return false;
    }
    validated = await formSociety?.current?.validate(e);
    if (!validated) {
      // must be set in a setTimeout to avoid a race condition
      setTimeout(() => setEnabled(true), 0);
      return false;
    }
  };

  const getDisabled = () => {
    return !enabled;
  };

  return (
    <form onSubmit={submitForm} aria-label="Questionnaire Infos OnBoarding">
      <EditPersonnalInfos ref={formPersonnal} me={me} onValidated={onBloomerDataValidated} hideSubmitButton={true} />
      <EditBloomerSociety
        ref={formSociety}
        society={billingSociety}
        onValidated={onSocietyDataValidated}
        hideSubmitButton={true}
      />
      <Button
        id="inscriptionSaveButton"
        // eslint-disable-next-line jsx-a11y/aria-role
        role={'button-onboarding'}
        none={'none'}
        textButton={!enabled ? "En cours d'envoi" : 'Sauvegarder mes données'}
        disable={getDisabled()}
        loading={getDisabled()}
        buttonType="submit"
      />
    </form>
  );
};

export default InscriptionBloomer;

export { InscriptionBloomer };
