import path from 'path';
import { lookup, extensions } from 'mime-types';
import { useMemo } from 'react';
import { ILogger } from '@bloomays-lib/types.shared';

export type EmbedProps = {
  src: string;
  type?: string;
  width?: string;
  height: string | number;
  logger?: ILogger;
};

export const Embed = ({ src, type, width = '100%', height, logger, ...props }: EmbedProps): JSX.Element => {
  const getCorrectMimeType = useMemo(() => {
    let mime: string | boolean = false;
    if (type) {
      if (extensions[type]) {
        mime = type;
      }
    }

    if (!mime && src) {
      const pathname = src.startsWith('http') ? new URL(src).pathname : src;
      const ext = path.extname(pathname);
      mime = lookup(ext);
    }
    return mime;
  }, [src, type]);

  const mimeType = getCorrectMimeType;
  logger?.debug(`Mime type: ${mimeType} for ${src}`);
  if (!mimeType) {
    return <div>{`Mime type not supported ${type}`}</div>;
  }
  return <embed src={src} type={mimeType} width={width} height={height} {...props} />;
};
