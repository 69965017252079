import React from 'react';

import MaterialModal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled, SxProps } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  additionnalCSS?: SxProps<Theme>;
  additionnalCSSCross?: SxProps<Theme>;
  children: React.ReactNode;
  noClose?: boolean;
};

const Paper = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: 'auto',
  maxHeight: '100vh',
  backgroundColor: theme.palette.background.paper,
  padding: '10px',
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%, -50%)`,
  '&:focus-visible': {
    outline: 'none',
  },
  [theme.breakpoints.down(915)]: {
    width: '90%',
  },
}));

const Cross = styled('div')(() => ({
  float: 'right',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Modal = (props: ModalProps): JSX.Element => {
  const { children, additionnalCSS, additionnalCSSCross } = props;
  const { open, onClose, noClose = false } = props;

  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      role="dialog"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      closeAfterTransition
    >
      <Paper sx={additionnalCSS}>
        {!noClose && (
          <Cross id="crossModal" onClick={onClose} sx={additionnalCSSCross}>
            <CloseIcon />
          </Cross>
        )}
        {children}
      </Paper>
    </MaterialModal>
  );
};

export default Modal;
export { Modal };
