import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { notify } from '../helpers/toastify';
import { LoaderSkeleton } from '@bloomays-lib/ui.shared';
import { IJobTitle } from '@bloomays-lib/types.shared';
import { UPSERT_TALENT_BASIC, FIND_TALENT_NONISO_DIRECTJOBTITLES, JOBTITLES } from '@bloomays-lib/adapter.api-talent';
import { ITalent } from '@bloomays-lib/types.shared';
import { DirectJobTitleManagmentProps } from '../components/organisms/DirectJobTitleManagement';
import { errorLogger } from '../helpers/error';

const ServiceDirectJobTitles = (WrappedComponent: React.ComponentType<any>) =>
  function Component() {
    const {
      loading: loadingJTs,
      error: errorJTs,
      data: dataJTs,
    } = useQuery<{ listJobTitles: IJobTitle[] }>(JOBTITLES, {
      context: { clientName: 'api.talents' },
    });

    const {
      loading: loadingTalents,
      error: errorTalents,
      data: dataTalents,
    } = useQuery<{ findTalentsWithNonIsoDirectJobTitles: ITalent[] }>(FIND_TALENT_NONISO_DIRECTJOBTITLES, {
      context: { clientName: 'api.talents' },
    });

    const [updateTalent] = useMutation<{
      upsertTalentBasic: ITalent;
    }>(UPSERT_TALENT_BASIC, { context: { clientName: 'api.talents' } });

    function notifyError(error: any) {
      errorLogger(error, {
        extraInfos: 'Internal server error, impossible to relaunch jobTitle !',
      });
      notify('error', error.message, error);
    }

    async function onUpdate(talent: ITalent, newJobTitle: string) {
      return (
        await updateTalent({
          variables: {
            input: {
              id: talent?.id,
              basics: {
                positionTitle: {
                  id: talent.positionTitle?.id,
                  jobTitle: {
                    label: newJobTitle,
                  },
                },
              },
            },
          },
        })
      ).data?.upsertTalentBasic;
    }

    useEffect(() => {
      if (!errorJTs) return;
      notifyError(errorJTs);
    }, [errorJTs]);

    useEffect(() => {
      if (!errorTalents) return;
      notifyError(errorTalents);
    }, [errorTalents]);

    if (loadingJTs || loadingTalents) {
      return <LoaderSkeleton height={600} width={800} />;
    }

    const jobTitles = dataJTs?.listJobTitles ?? [];
    const talents = dataTalents?.findTalentsWithNonIsoDirectJobTitles ?? [];

    const injectedProps: DirectJobTitleManagmentProps = {
      labels: jobTitles,
      talents: talents,
      onError: notifyError,
      onUpdate: onUpdate,
    };

    return <WrappedComponent {...injectedProps} />;
  };

export default ServiceDirectJobTitles;
export { ServiceDirectJobTitles };
