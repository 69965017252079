import { gql } from '@apollo/client';

export const UPSERT_TALENT = gql`
  mutation upsertTalent($input: TalentV2Input!) {
    upsertTalent(input: $input) {
      id
      owner
      createdAt
    }
  }
`;

export const DELETE_TALENT = gql`
  mutation deleteTalent($objectID: String!, $isRGPD: Boolean) {
    deleteTalent(objectID: $objectID, isRGPD: $isRGPD)
  }
`;

export const DELETE_TALENT_V2 = gql`
  mutation deleteTalent($id: String!, $isRGPD: Boolean!) {
    deleteTalent(id: $id, isRGPD: $isRGPD) {
      id
      owner
    }
  }
`;

export const UPSERT_CV = gql`
  mutation uploadCVFile($input: UploadCVQueryArgs!) {
    uploadCVFile(input: $input) {
      id
      cv
    }
  }
`;

export const UPSERT_TALENT_BASIC = gql`
  mutation upsertTalentBasic($input: TalentBasicV2Input!) {
    upsertTalentBasic(input: $input) {
      id
      createdAt
      owner
    }
  }
`;

export const UPSERT_LANGUAGE = gql`
  mutation upsertLanguages($languageTalent: [LanguageV2Input!]!) {
    upsertLanguages(languageTalent: $languageTalent) {
      id
    }
  }
`;

export const UPSERT_PROFILES = gql`
  mutation upsertProfiles($profilesTalent: [ProfileInput!]!) {
    upsertProfiles(profilesTalent: $profilesTalent) {
      id
    }
  }
`;

export const UPSERT_DIRECT_SKILLS = gql`
  mutation upsertDirectSkills($directSkills: [DirectSkillV2Input!]!) {
    upsertDirectSkills(directSkills: $directSkills) {
      id
    }
  }
`;

export const CREATE_ACTIVITY_LOG = gql`
  mutation createActivityLog($params: ActivityLogInput!) {
    createActivityLog(params: $params) {
      logs {
        id
      }
    }
  }
`;

export const UPSERT_JOB = gql`
  mutation upsertJobTalent($jobTalent: JobV2Input!) {
    upsertJobTalent(jobTalent: $jobTalent) {
      id
    }
  }
`;

export const DELETE_JOBS = gql`
  mutation deleteJobTalent($jobTalentId: String!) {
    deleteJobTalent(jobTalentId: $jobTalentId) {
      id
    }
  }
`;

export const UPSERT_EDUCATION = gql`
  mutation upsertEducationTalent($education: EducationV2Input!) {
    upsertEducationTalent(education: $education) {
      id
    }
  }
`;

export const DELETE_EDUCATION = gql`
  mutation deleteEducationTalent($educationTalentId: String!) {
    deleteEducationTalent(educationTalentId: $educationTalentId) {
      id
    }
  }
`;

export const UPDATE_SKILL = gql`
  mutation updateSkill($skill: SkillUpdateInput!) {
    updateSkill(skill: $skill) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const FLAT_SKILL = gql`
  mutation flatSkills($dryRun: Boolean, $skillId: String) {
    flatSkills(dryRun: $dryRun, skillId: $skillId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation deleteSkill($skillId: String!) {
    deleteSkill(skillId: $skillId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const UPDATE_JOBTITLE = gql`
  mutation updateJobTitle($jobTitle: JobTitleUpdateInput!) {
    updateJobTitle(jobTitle: $jobTitle) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const CREATE_JOBTITLE = gql`
  mutation createJobTitle($jobTitle: JobTitleInput!) {
    createJobTitle(jobTitle: $jobTitle) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const FLAT_JOBTITLE = gql`
  mutation flatJobTitles($dryRun: Boolean, $jobTitleId: String) {
    flatJobTitles(dryRun: $dryRun, jobTitleId: $jobTitleId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const DELETE_JOBTITLE = gql`
  mutation deleteJobTitle($jobTitleId: String!) {
    deleteJobTitle(jobTitleId: $jobTitleId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const DELETE_UNUSED_JOBTITLE = gql`
  mutation deleteUnusedJobTitle {
    deleteUnusedJobTitle
  }
`;

export const MERGE_SKILL_BY_ID = gql`
  mutation mergeSkillsById($dryRun: Boolean, $mergeInSkillId: String!, $mergeToSkillId: String!) {
    mergeSkillsById(dryRun: $dryRun, mergeInSkillId: $mergeInSkillId, mergeToSkillId: $mergeToSkillId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;

export const MERGE_JOBTITLE_BY_ID = gql`
  mutation mergeJobTitlesById($dryRun: Boolean, $mergeInJobTitleId: String!, $mergeToJobTitleId: String!) {
    mergeJobTitlesById(dryRun: $dryRun, mergeInJobTitleId: $mergeInJobTitleId, mergeToJobTitleId: $mergeToJobTitleId) {
      iso
      label
      id
      alternateLabels
    }
  }
`;
