/* eslint-disable jsx-a11y/accessible-emoji */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import isFuture from 'date-fns/isFuture';
import Modal from '../atoms/Modal';
import {
  ExtensionAssessmentGQL,
  GetExtensionAssessmentGQLResult,
  IMissionCardDetails,
  Role,
} from '@bloomays-lib/types.shared';
import MissionDetailCard from './MissionDetailCard';
import { ModifyMission } from './ModifyMission';
import { ExtensionAssessment } from './ExtensionAssessment';
import { useMutation, useQuery } from '@apollo/client';
import {
  DELETE_MISSION,
  GET_EXTENSION_ASSESSMENT,
  UPSERT_EXTESION_ASSESSMENT,
} from '@bloomays-lib/adapter.api-bloomer';
import { dateFormat } from '@bloomays-lib/utils.shared';
import isUndefined from 'lodash/isUndefined';
import compact from 'lodash/fp/compact';
import BasicTable from './BasicTable';
import { useAuth } from '../../auth/AuthProvider';
import DeleteActionPopin from './DeleteActionPopin';
import { useTranslation } from 'react-i18next';
import { notify } from '../../helpers/toastify';
import { LoaderPending } from '@bloomays-lib/ui.shared';
import { ShortenMission } from './ShortenMission';
import { CreateAmendment } from './CreateAmendment';

export type PopinType = 'shorten' | 'amendment' | 'modify' | 'assessment' | 'prices' | 'delete' | null;

export type MissionCardProps = {
  mission: IMissionCardDetails; // TODO refactor --> ce type est faux ! le type de retour est lié à GET_ONE_FULL_MISSION !!
  popinToOpen: PopinType;
  resetPopinToOpen: () => void;
};

type MenuOption = {
  name: string;
  action: () => void;
};

const buildMenu = (
  navigate: NavigateFunction,
  mission: IMissionCardDetails,
  setOpenPopin: Dispatch<SetStateAction<PopinType>>,
  isSystem: boolean,
): MenuOption[] => {
  return compact([
    {
      name: '👀 Voir les cra',
      action: () => navigate(`/missions/${mission.recordId}/activities`),
    },
    {
      name: '⚖️ Gérer les contrats',
      action: () => navigate(`/missions/${mission.recordId}/contracts`),
    },
    {
      name: '📝 Gérer les factures',
      action: () => navigate(`/mission/${mission.recordId}`),
    },
    mission.isActive
      ? {
          name: '😢 Écourter la mission',
          action: () => setOpenPopin('shorten'),
        }
      : null,
    mission.isActive
      ? {
          name: '🥳 Créer un avenant',
          action: () => setOpenPopin('amendment'),
        }
      : null,
    mission.isActive || isFuture(new Date(mission.beginMission))
      ? {
          name: '📝  Modifier la mission',
          action: () => setOpenPopin('modify'),
        }
      : null,
    mission.isActive
      ? {
          name: '🔮 Estimer la prolongation',
          action: () => setOpenPopin('assessment'),
        }
      : null,
    mission.prices
      ? {
          name: '👀 Historique des prix',
          action: () => setOpenPopin('prices'),
        }
      : null,
    isSystem
      ? {
          name: '🛑 Supprimer mission',
          action: () => setOpenPopin('delete'),
        }
      : null,
  ]);
};

const MissionCard = ({ mission, popinToOpen, resetPopinToOpen }: MissionCardProps): JSX.Element => {
  const { t } = useTranslation(['random', 'mission']);
  const navigate = useNavigate();
  const auth = useAuth();
  const isSystem = auth?.user?.roles?.includes(Role.System) || false;
  const [pendingOperation, setPendingOperation] = useState<boolean>(false);

  const [openPopin, setOpenPopin] = useState<PopinType>(popinToOpen);

  const handleClose = () => {
    setOpenPopin(null);
    resetPopinToOpen();
  };

  const { data } = useQuery<GetExtensionAssessmentGQLResult>(GET_EXTENSION_ASSESSMENT, {
    variables: { missionId: mission.recordId },
  });
  const extensionAssessment: ExtensionAssessmentGQL | null = !isUndefined(data) ? data.getExtensionAssessment : null;
  const [upsertExtensionAssessmentMutation] = useMutation(UPSERT_EXTESION_ASSESSMENT);
  const upsertExtensionAssessment = async (missionId: string, assessment: number, newEndDate: Date): Promise<void> => {
    await upsertExtensionAssessmentMutation({
      variables: {
        input: {
          missionId,
          assessment,
          newEndDate: dateFormat(newEndDate, 'yyyy-MM-dd'),
        },
      },
      refetchQueries: [
        {
          query: GET_EXTENSION_ASSESSMENT,
          variables: { missionId: mission.recordId },
        },
      ],
    });
  };

  const [deleteMissionMutation, { loading: loadingDeleteMission, error: errorDeleteMission, data: deleteMissionData }] =
    useMutation(DELETE_MISSION);

  const deleteMission = async (): Promise<void> => {
    setOpenPopin(null);
    await deleteMissionMutation({
      variables: { missionId: mission.recordId },
    });
  };

  useEffect(() => {
    setOpenPopin(popinToOpen);
  }, [popinToOpen]);

  useEffect(() => {
    if (errorDeleteMission) {
      notify('error', errorDeleteMission.message);
    }
  }, [errorDeleteMission]);

  useEffect(() => {
    if (deleteMissionData) {
      navigate('/dashboard');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMissionData]);

  const actions = buildMenu(navigate, mission, setOpenPopin, isSystem);

  return (
    <>
      <Modal additionnalCSS={{ width: '70%' }} open={openPopin === 'shorten'} onClose={handleClose}>
        {pendingOperation ? <LoaderPending text="Attendez, ça prendra quelques secondes..." /> : null}
        <ShortenMission handleClose={handleClose} mission={mission} setPendingOperation={setPendingOperation} />
      </Modal>
      <Modal
        additionnalCSS={{ width: '70%', overflow: 'scroll' }}
        open={openPopin === 'amendment'}
        onClose={handleClose}
      >
        {pendingOperation ? (
          <LoaderPending text="Attendez, ça prendra quelques secondes. Nous lançons le processus de création d'avenant de mission..." />
        ) : null}
        <CreateAmendment handleClose={handleClose} mission={mission} setPendingOperation={setPendingOperation} />
      </Modal>
      <Modal additionnalCSS={{ width: '70%' }} open={openPopin === 'modify'} onClose={handleClose}>
        {pendingOperation ? <LoaderPending text="Attendez, on actualise la mission..." /> : null}
        <ModifyMission mission={mission} handleClose={handleClose} setPendingMissionModify={setPendingOperation} />
      </Modal>
      <Modal additionnalCSS={{ width: '75%' }} open={openPopin === 'assessment'} onClose={handleClose}>
        <ExtensionAssessment
          missionId={mission.recordId}
          currentEndDate={new Date(mission.endMission)}
          upsertExtensionAssessment={upsertExtensionAssessment}
          currentExtensionAssessment={extensionAssessment}
        />
      </Modal>

      <Modal additionnalCSS={{ width: '50%' }} open={openPopin === 'prices'} onClose={handleClose}>
        👀 Historique des Prix
        <BasicTable columns={['Début de validité', 'Achat', 'Vente', 'TVA', 'Astreinte']}>
          {mission.prices &&
            mission.prices?.map((p, i) => (
              <tr key={`price-${i}`}>
                <td>{p.startDate?.toLocaleDateString() || ''}</td>
                <td>{`${p.purchasePricePerDayWithoutTaxes} €`}</td>
                <td>{`${p.salePricePerDayWithoutTaxes} €`}</td>
                <td>{`${p.taxes} %`}</td>
                <td>{`${p.astreinteCoef || 200} %`}</td>
              </tr>
            ))}
        </BasicTable>
      </Modal>
      <>
        {loadingDeleteMission && (
          <LoaderPending text="Attendez, ça prendra quelques secondes. Nous supprimons la mission d'airtable et les KPIs dans notre base..." />
        )}
        {openPopin === 'delete' && (
          <DeleteActionPopin
            opened={openPopin === 'delete'}
            onClose={handleClose}
            confirmLabel={t('deleteWarning', { ns: 'mission' })}
            deleteConfirmLabel={t('deleteWarning', { ns: 'mission' })}
            expectedValue="SUPPRIMER"
            deleteAction={() => {
              return deleteMission();
            }}
          />
        )}
      </>
      <MissionDetailCard
        type="mission"
        data={{ mission: mission, actions: actions, currentExtensionAssessment: extensionAssessment }}
      />
    </>
  );
};

export default MissionCard;
