import { CRAStatus, InvoiceStatus, CRADashboardItemGQL } from '@bloomays-lib/types.shared';
import { styled } from '@mui/material/styles';
import { LoaderSkeleton, ActionButton, FileViewer } from '@bloomays-lib/ui.shared';
import Modal from '../atoms/Modal';
import RawDataGrid from '../molecules/DataGrid';
import {
  GridCellParams,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/fp/format';
import CraRelaunchAction from '../molecules/CraRelaunchAction';
import { useEffect, useState } from 'react';
import ChronologicalHistory from './ChronologicalHistory';
import DetailsSectionCard from '../molecules/DetailsSectionCard';
import add from 'date-fns/fp/add';
import lastDayOfMonth from 'date-fns/fp/lastDayOfMonth';
import pipe from 'lodash/fp/pipe';
import values from 'lodash/fp/values';
import Step from '../molecules/Step';
import ActionInvoice from '../molecules/ActionInvoice';
import IconButton from '@mui/material/IconButton';
import ActionForceCRA from '../molecules/ActionForceCRA';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../auth/AuthProvider';

type ActivityDashboardProps = {
  uniqueActivityDashboardId: string;
  missionMode: boolean;
  loading: boolean;
  rows: CRADashboardItemGQL[];
  createActivityCustomerInvoice: (v: { variables: { activityRecordID: string; force: boolean } }) => void;
  createActivitySupplierInvoice: (v: {
    variables: { activityRecordID: string; force: boolean; byPassComplianceCheck: boolean };
  }) => void;
  sendBloomerRelaunch: (v: { variables: { missionId?: string; month: string } }) => void;
  sendClientRelaunch: (v: { variables: { signingDocumentId?: string | null } }) => void;
  forceCRA: (v: { variables: { activityRecordID: string; force: boolean } }) => void;
};

const renderIconLabel = (label: string, emoji?: string, onClick?: () => void) => {
  return (
    <IconButton size="small" onClick={onClick} title={label}>
      {emoji || label}
    </IconButton>
  );
};

// 'Non créée' | 'En erreur' | 'Créée à valider' | 'Créée et finalisée' ;
const invoiceStatus: Record<InvoiceStatus, { value: InvoiceStatus; label: string }> = {
  'Non créée': { value: 'Non créée', label: '🚧 Non créée' },
  'En erreur': { value: 'En erreur', label: '🚨 En erreur' },
  'Créée à valider': { value: 'Créée à valider', label: '☑️ Créée à valider' },
  'Créée et finalisée': { value: 'Créée et finalisée', label: '✅ Créée et finalisée' },
  'En cours': { value: 'En cours', label: '🔄 En cours' },
};
const statusCRA: CRAStatus[] = [
  'Non crée',
  'Signature non créee',
  'Attente signature Bloomer',
  'Attente signature Client',
  'Validé client',
];

const renderInvoiceStatusCell =
  (
    rowColName: 'bloomerInvoiceStatus' | 'clientInvoiceStatus',
    rowErrorColName: 'bloomerInvoiceLastError' | 'clientInvoiceLastError',
    clickHandler?: (cRADashboardItemGQL: CRADashboardItemGQL) => () => void,
  ) =>
  (params: GridRenderCellParams<CRADashboardItemGQL>) => {
    // rendering for group header rows
    if (params.rowNode.type === 'group') {
      if (params.field !== '__row_group_by_columns_group__') return '';
      const groupingKey = params.rowNode.groupingKey as InvoiceStatus;
      if (!groupingKey) return;
      return invoiceStatus[groupingKey].label;
    }
    const rowValue = params.row[rowColName];
    switch (rowValue) {
      case 'Non créée':
        if (params.row.invoiceURL && params.row.status === 'Validé client') {
          return renderIconLabel('A créer', '🚧', clickHandler ? clickHandler(params.row) : undefined);
        }
        return renderIconLabel('Non créée', '🚧');
      case 'En erreur':
        return renderIconLabel(
          params.row[rowErrorColName] || 'Contacter service technique',
          '🚨',
          clickHandler ? clickHandler(params.row) : undefined,
        );
      case 'Créée à valider':
        return renderIconLabel(
          `Créée à valider\n${params.row[rowErrorColName]}`,
          '☑️',
          clickHandler ? clickHandler(params.row) : undefined,
        );
      case 'Créée et finalisée':
        return renderIconLabel('Créée et finalisée', '✅', clickHandler ? clickHandler(params.row) : undefined);
      case 'En cours':
        return renderIconLabel('En cours', '🔄');
      default:
        return '';
    }
  };

const renderCRAStatusCell =
  (clickHandler: (cRADashboardItemGQL: CRADashboardItemGQL) => () => void) =>
  (params: GridRenderCellParams<CRADashboardItemGQL>) => {
    // rendering for group header rows
    if (params.rowNode.type === 'group') {
      if (params.field !== '__row_group_by_columns_group__') return '';
      const groupingKey = params.rowNode.groupingKey as CRAStatus;
      if (!groupingKey) return;
      return (
        <>
          <Step step={(statusCRA.findIndex((s) => s === groupingKey) + 1).toString()}></Step>&nbsp;{groupingKey}
        </>
      );
    }
    // rendering for normal rows
    const status = params.row.status;
    switch (status) {
      case 'Attente signature Bloomer':
      case 'Attente signature Client':
      case 'Signature non créee':
        return (
          <Clickable direction="row" onClick={clickHandler(params.row)}>
            <Step step={(statusCRA.findIndex((s) => s === status) + 1).toString()}></Step>&nbsp;{status}
          </Clickable>
        );
    }
    return (
      <>
        <Step step={(statusCRA.findIndex((s) => s === status) + 1).toString()}></Step>&nbsp;{status}
      </>
    );
  };

const ActivityDashboard = (props: ActivityDashboardProps) => {
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user?.token) {
      setToken(auth.user?.token);
    }
  }, [auth]);

  const { t } = useTranslation(['monthlyActitivyDashboard', 'mission']);
  const [historyPopinState, setHistoryPopinState] = useState({ missionId: '', open: false });
  const [fileViewerPath, setFileViewerPath] = useState<string | undefined>(undefined);
  const [openActionSupplierInvoice, setOpenActionSupplierInvoice] = useState<{
    craDashboardItemGQL?: CRADashboardItemGQL;
    open: boolean;
  }>({ open: false });
  const [openActionCustomerInvoice, setOpenActionCustomerInvoice] = useState<{
    craDashboardItemGQL?: CRADashboardItemGQL;
    open: boolean;
  }>({ open: false });
  const [openActionForceCRA, setOpenActionForceCRA] = useState<{
    craDashboardItemGQL?: CRADashboardItemGQL;
    open: boolean;
  }>({ open: false });
  const closeHistoryPopin = () => {
    setHistoryPopinState({ missionId: '', open: false });
  };
  const openHistoryPopin = (missionId: string) => {
    setHistoryPopinState({ missionId, open: true });
  };
  const [npsPopinState, setNpsPopinState] = useState<{
    data: {
      bloomer: string;
      contactOperationel: string;
      score: number;
      comment: string | null;
      date: Date;
    } | null;
    open: boolean;
  }>({
    data: null,
    open: false,
  });
  const closeNPSPopin = () => {
    setNpsPopinState({ data: null, open: false });
  };
  const openNPSPopin = (
    bloomer: string,
    contactOperationel: string,
    score: number,
    comment: string | null,
    date: Date,
  ) => {
    setNpsPopinState({ data: { bloomer, contactOperationel, score, comment, date }, open: true });
  };

  const { loading, rows, sendBloomerRelaunch, sendClientRelaunch } = props;

  const pageSize = 100;
  if (loading) return <LoaderSkeleton width={'100%'} />;

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'CRA',
      children: [
        { field: 'missionId' },
        { field: 'activityId' },
        { field: 'month' },
        { field: 'status' },
        { field: 'craURL' },
      ],
    },
    {
      groupId: 'Bloomer',
      description: 'Bloomer',
      children: [
        { field: 'bloomerSocietyName' },
        { field: 'invoiceURL' },
        { field: 'bloomerScore' },
        { field: 'bloomerInvoiceBilled' },
        { field: 'paymentDate' },
        { field: 'bloomerInvoiceStatus' },
      ],
    },
    {
      groupId: 'Client',
      children: [
        { field: 'clientSocietyName' },
        { field: 'clientInvoiceStatus' },
        { field: 'contactOperationsName' },
        { field: 'clientScore' },
      ],
    },
  ];

  let columns: GridColDef<CRADashboardItemGQL>[] = [];
  if (props.missionMode) {
    columns.push({
      field: 'month',
      headerName: 'Mois',
      headerClassName: 'header',
      groupable: false,
      sortable: true,
      filterable: true,
      type: 'date',
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => {
        const item = params.row;
        if (params.rowNode.type === 'group' || !item.activityMonth) {
          return;
        }
        return new Date(item.activityMonth);
      },
    });
  } else {
    columns.push({
      field: 'missionId',
      headerName: t('missionId', { ns: 'mission' }),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    });
  }
  columns = columns.concat([
    {
      field: 'activityId',
      headerName: t('activityId', { ns: 'mission' }),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    },
    {
      field: 'status',
      headerName: t('status'),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      type: 'singleSelect',
      valueOptions: statusCRA,
      minWidth: 218,
      valueFormatter: (params: GridValueFormatterParams<CRADashboardItemGQL>) => params.value,
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => params.row.status,
      renderCell: renderCRAStatusCell((row) => () => setOpenActionForceCRA({ open: true, craDashboardItemGQL: row })),
    },
    {
      field: 'craURL',
      headerName: t('craURL'),
      headerClassName: 'header',
      align: 'center',
      cellClassName: 'cellStyle',
      groupable: false,
      sortable: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        return (
          params.row.craURL && (
            <ActionButton
              actionType="Preview"
              variant="contained"
              color="secondary"
              onClick={() => setFileViewerPath(params.row.craURL || '')}
              title={params.row.craURL}
            />
          )
        );
      },
    },
    {
      field: 'clientSocietyName',
      headerName: t('clientSocietyName', { ns: 'mission' }),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    },
    {
      field: 'clientInvoiceStatus',
      headerName: t('clientInvoiceStatus'),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      cellClassName: 'cellStyle',
      type: 'singleSelect',
      align: 'center',
      valueOptions: values(invoiceStatus),
      renderCell: renderInvoiceStatusCell(
        'clientInvoiceStatus',
        'clientInvoiceLastError',
        (craDashboardItemGQL: CRADashboardItemGQL) => () => {
          setOpenActionCustomerInvoice({ open: true, craDashboardItemGQL });
          return undefined;
        },
      ),
    },

    {
      field: 'contactOperationsName',
      headerName: t('contactOperationsName'),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      filterable: true,
      cellClassName: 'cellStyle',
    },
    {
      field: 'clientScore',
      headerName: t('clientScore'),
      headerClassName: 'header',
      sortable: true,
      groupable: false,
      filterable: true,
      type: 'number',
      cellClassName: (params: GridCellParams<CRADashboardItemGQL>) => {
        if (params.row.clientScore === null || params.row.clientScore > 6 || params.rowNode.type === 'group')
          return 'normalCell';
        return 'errorCell';
      },
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        const { clientComment, clientScoreDate, bloomerFullName, contactOperationsName, clientScore } = params.row;

        if (clientScore === null) return;
        return (
          <NPSScore
            onClick={() => {
              openNPSPopin(bloomerFullName, contactOperationsName, clientScore, clientComment, clientScoreDate as Date);
            }}
          >
            {clientScore}
          </NPSScore>
        );
      },
    },
    {
      field: 'bloomerFullName',
      headerName: t('bloomerFullName', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      minWidth: 200,
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => params.row.bloomerFullName,
    },
    {
      field: 'bloomerSocietyName',
      headerName: t('bloomerSocietyName', { ns: 'mission' }),
      headerClassName: 'header',
      groupable: true,
      sortable: true,
      filterable: true,
      minWidth: 200,
      cellClassName: 'cellStyle',
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => params.row.bloomerSocietyName,
    },
    {
      field: 'bloomerScore',
      headerName: t('bloomerScore'),
      headerClassName: 'header',
      sortable: true,
      groupable: false,
      filterable: true,
      type: 'number',
      cellClassName: (params: GridCellParams<CRADashboardItemGQL>) => {
        if (params.row.bloomerScore === null || params.row.bloomerScore > 6 || params.rowNode.type === 'group')
          return 'normalCell';
        return 'errorCell';
      },
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        const { bloomerScore, bloomerFullName, bloomerComment, bloomerScoreDate, contactOperationsName } = params.row;

        if (bloomerScore === null) return;
        return (
          <NPSScore
            onClick={() => {
              openNPSPopin(
                bloomerFullName,
                contactOperationsName,
                bloomerScore,
                bloomerComment,
                bloomerScoreDate as Date,
              );
            }}
          >
            {bloomerScore}
          </NPSScore>
        );
      },
    },

    {
      field: 'invoiceURL',
      headerName: t('invoiceURL'),
      headerClassName: 'header',
      align: 'center',
      cellClassName: 'cellStyle',
      groupable: false,
      sortable: true,
      filterable: false,
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        return (
          params.row.invoiceURL && (
            <ActionButton
              actionType="Preview"
              variant="contained"
              color="secondary"
              onClick={() => setFileViewerPath(params.row.invoiceURL || '')}
              title={params.row.invoiceURL}
            />
          )
        );
      },
    },
    {
      field: 'bloomerInvoiceStatus',
      headerName: t('bloomerInvoiceBilled'),
      headerClassName: 'header',
      sortable: true,
      groupable: true,
      align: 'center',
      type: 'singleSelect',
      cellClassName: 'cellStyle',
      valueOptions: values(invoiceStatus),
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => params.row.bloomerInvoiceStatus,
      renderCell: renderInvoiceStatusCell(
        'bloomerInvoiceStatus',
        'bloomerInvoiceLastError',
        (craDashboardItemGQL: CRADashboardItemGQL) => () => {
          setOpenActionSupplierInvoice({ open: true, craDashboardItemGQL });
          return undefined;
        },
      ),
    },
    {
      field: 'paymentDate',
      headerName: t('paymentEstimed'),
      align: 'center',
      filterable: true,
      headerClassName: 'header',
      cellClassName: 'cellStyle',
      type: 'date',
      valueGetter: (params: GridValueGetterParams<CRADashboardItemGQL>) => {
        const item = params.row;
        if (item.invoiceURL && item.dateUploadInvoice) {
          return add({ days: item.bloomerPayDateDays || 45 })(new Date(item.dateUploadInvoice));
        } else if (item.invoiceURL === null && item.bloomerInvoiceBilled && item.activityMonth) {
          return pipe(lastDayOfMonth, add({ days: item.bloomerPayDateDays || 45 }))(new Date(item.activityMonth));
        }
      },
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        const item = params.row;
        if (item.invoiceURL && item.dateUploadInvoice) {
          return pipe(
            add({ days: item.bloomerPayDateDays || 45 }),
            format('dd/MM/yyyy'),
          )(new Date(item.dateUploadInvoice));
        } else if (item.invoiceURL === null && item.bloomerInvoiceBilled && item.activityMonth) {
          return pipe(
            lastDayOfMonth,
            add({ days: item.bloomerPayDateDays || 45 }),
            format('dd/MM/yyyy'),
          )(new Date(item.activityMonth));
        }
      },
    },
    {
      field: 'actions',
      headerClassName: 'header',
      sortable: false,
      groupable: false,
      filterable: false,
      cellClassName: 'actionCell',
      headerName: 'Actions',
      headerAlign: 'center',
      align: 'left',
      renderCell: (params: GridRenderCellParams<CRADashboardItemGQL>) => {
        if (params.rowNode.type === 'group') {
          return '';
        }
        const { missionId, activityId, status, signingDocumentId, clientInvoiceStatus, activityMonth } = params.row;
        return (
          <IconsContainer>
            <ActionButton
              actionType="Preview"
              variant="contained"
              onClick={() => {
                openHistoryPopin(missionId);
              }}
              title={'Consulter historique'}
            />
            {activityMonth && (
              <CraRelaunchAction
                missionId={missionId}
                activityId={activityId || undefined}
                CRAStatus={status}
                clientInvoiceStatus={clientInvoiceStatus}
                signingDocumentId={signingDocumentId || undefined}
                sendBloomerRelaunch={sendBloomerRelaunch}
                sendClientRelaunch={sendClientRelaunch}
                month={activityMonth}
              />
            )}
          </IconsContainer>
        );
      },
    },
  ]);

  return (
    <div style={{ width: '100%' }}>
      <Modal
        open={!!fileViewerPath}
        onClose={() => setFileViewerPath(undefined)}
        additionnalCSS={{
          width: '822px',
          padding: '32px',
          height: '90%',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <FileViewer width="100%" height="100%" url={fileViewerPath as string} accessToken={token} />
      </Modal>
      <Modal
        open={historyPopinState.open}
        onClose={closeHistoryPopin}
        additionnalCSS={{
          width: '622px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        <ChronologicalHistory missionId={historyPopinState.missionId} />
      </Modal>
      <Modal
        open={npsPopinState.open}
        onClose={closeNPSPopin}
        additionnalCSS={{
          width: '622px',
          padding: '32px',
          border: 'none',
          borderRadius: '20px',
        }}
      >
        {npsPopinState.data ? (
          <DetailsSectionCard
            title={`Note du NPS du Client ${npsPopinState.data.contactOperationel} sur le Bloomer ${
              npsPopinState.data.bloomer
            } du ${format('dd/MM/yyy', new Date(npsPopinState.data.date))}`}
            details={`Score: ${npsPopinState.data.score}`}
            text={`Commentaire: ${npsPopinState.data.comment || 'Pas de commentaire.'}`}
          />
        ) : (
          ''
        )}
      </Modal>
      <Modal open={openActionSupplierInvoice.open} onClose={() => setOpenActionSupplierInvoice({ open: false })}>
        {openActionSupplierInvoice.craDashboardItemGQL ? (
          <ActionInvoice
            mode="supplier"
            invoiceLabel={openActionSupplierInvoice.craDashboardItemGQL.activityId as string}
            activityId={openActionSupplierInvoice.craDashboardItemGQL.activityId as string}
            lastError={openActionSupplierInvoice.craDashboardItemGQL.bloomerInvoiceLastError as string}
            invoiceStatus={openActionSupplierInvoice.craDashboardItemGQL.bloomerInvoiceStatus as InvoiceStatus}
            clickHandler={async (force: boolean, byPass: boolean) => {
              await props.createActivitySupplierInvoice({
                variables: {
                  activityRecordID: openActionSupplierInvoice.craDashboardItemGQL?.activityId as string,
                  force: force,
                  byPassComplianceCheck: byPass,
                },
              });
              setOpenActionSupplierInvoice({ open: false });
            }}
          />
        ) : undefined}
      </Modal>
      <Modal open={openActionCustomerInvoice.open} onClose={() => setOpenActionCustomerInvoice({ open: false })}>
        {openActionCustomerInvoice.craDashboardItemGQL ? (
          <ActionInvoice
            mode="customer"
            invoiceLabel={openActionCustomerInvoice.craDashboardItemGQL.activityId as string}
            activityId={openActionCustomerInvoice.craDashboardItemGQL.activityId as string}
            lastError={openActionCustomerInvoice.craDashboardItemGQL.clientInvoiceLastError as string}
            invoiceStatus={openActionCustomerInvoice.craDashboardItemGQL?.clientInvoiceStatus as InvoiceStatus}
            clickHandler={async (force: boolean) => {
              await props.createActivityCustomerInvoice({
                variables: {
                  activityRecordID: openActionCustomerInvoice.craDashboardItemGQL?.activityId as string,
                  force: force,
                },
              });
              setOpenActionCustomerInvoice({ open: false });
            }}
          />
        ) : undefined}
      </Modal>
      <Modal open={openActionForceCRA.open} onClose={() => setOpenActionForceCRA({ open: false })}>
        {openActionForceCRA.craDashboardItemGQL ? (
          <ActionForceCRA
            activityId={openActionForceCRA.craDashboardItemGQL.activityId as string}
            craStatus={openActionForceCRA.craDashboardItemGQL?.status as CRAStatus}
            clickHandler={async (force: boolean) => {
              await props.forceCRA({
                variables: {
                  activityRecordID: openActionForceCRA.craDashboardItemGQL?.activityId as string,
                  force: force,
                },
              });
              setOpenActionForceCRA({ open: false });
            }}
          />
        ) : undefined}
      </Modal>
      <DataGrid
        uniqueDatagridId={props.uniqueActivityDashboardId}
        initialState={{
          sorting: {
            sortModel: [
              { field: 'bloomerFullName', sort: 'asc' },
              { field: 'month', sort: 'desc' },
            ],
          },
          pinnedColumns: {
            left: ['actions', 'bloomerFullName'],
          },
        }}
        fetchLoading={loading}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
        hideFooterPagination={false}
        sortingMode="client"
        filterMode="client"
        rows={rows}
        getRowId={(row: CRADashboardItemGQL) => `${row.missionId}-${row.activityId}`}
        columns={columns}
        pageSize={pageSize}
        pagination={true}
        columnGroupingModel={columnGroupingModel}
        sx={{
          width: '100%',
        }}
      />
    </div>
  );
};

export default ActivityDashboard;

const DataGrid = styled(RawDataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
  },
}));

const NPSScore = styled('div')(() => ({
  margin: 'auto',
  border: 'none',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const IconsContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const Clickable = styled(Stack)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));
