import { Title } from '../atoms/Title';
import WithErrorBoundary from '../organisms/ErrorBoundary';

import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import MissionPlanning from '../organisms/MissionPlanning';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ALL_MISSIONS_WITH_EXTENSION, DASHBOARD_MISSION_DATA } from '@bloomays-lib/adapter.api-bloomer';
import { errorLogger } from '../../helpers/error';
import { notify } from '../../helpers/toastify';
import { DashboardMissionData, KPIType, AllMissionWithExtensionGQLItemResult } from '@bloomays-lib/types.shared';
import { useEffect, useState } from 'react';
import { SingleLineText } from '@bloomays-lib/ui.shared';
import { DatePicker } from '../atoms/DatePicker';
import Modal from '../atoms/Modal';
import uniq from 'lodash/fp/uniq';
import DetailsKPIsMissions from '../organisms/DetailsKPIsMissions';

type MissionsPopinState = {
  show: boolean;
  missionIds: string[];
  estimatedMissionIds: string[];
  month: string;
  kpi?: KPIType;
};

const initValueYear = {
  currentYear: [],
  lastYear: [],
  diff: [],
  average: {
    currentYear: 0,
    lastYear: 0,
  },
};

const initialValue: DashboardMissionData = {
  D: initValueYear,
  NS: initValueYear,
  M: initValueYear,
  F: initValueYear,
  EB: initValueYear,
  BE: initValueYear,
  BR: initValueYear,
};

const MissionPlanningDashboard = (): JSX.Element => {
  const { t } = useTranslation(['missionPlanning', 'random', 'KPI', 'mission']);
  const [date, setDate] = useState(new Date());
  const [datePickerValue, setDatePickerValue] = useState(new Date());
  const [popinState, setPopinState] = useState<MissionsPopinState>({
    show: false,
    missionIds: [] as string[],
    estimatedMissionIds: [] as string[],
    month: '',
  });

  const {
    loading: loadingKpis,
    data: kpis,
    error: fetchingKpisError,
  } = useQuery<{
    dashboardMissionData: DashboardMissionData;
  }>(DASHBOARD_MISSION_DATA, {
    variables: { startDate: date.toISOString() },
    context: { clientName: 'api.bloomers' },
  });

  const [fetchMissions, { error: errorFetchingMissions, data: missions, loading: loadingMissions }] = useLazyQuery<{
    allMissions: { results: AllMissionWithExtensionGQLItemResult[] };
  }>(ALL_MISSIONS_WITH_EXTENSION, {
    fetchPolicy: 'network-only',
  });

  const handleClose = () => {
    setPopinState({
      show: false,
      missionIds: [],
      estimatedMissionIds: [],
      month: '',
      kpi: undefined,
    });
  };

  useEffect(() => {
    if (fetchingKpisError) {
      errorLogger(fetchingKpisError, {
        extraInfos: 'Error, enable to retrieve mission data!',
      });
      notify('error', t('randomLKError', { ns: 'random' }), fetchingKpisError);
    }
  }, [fetchingKpisError, t]);

  useEffect(() => {
    if (errorFetchingMissions) {
      errorLogger(errorFetchingMissions, {
        extraInfos: 'Error, enable to retrieve missions',
      });
      notify('error', t('randomLKError', { ns: 'random' }), errorFetchingMissions);
    }
  }, [errorFetchingMissions, t]);

  const openMissionsPopin = (kpi: KPIType, month: string, missionIds: string[], estimatedMissionIds: string[]) => {
    setPopinState({
      show: true,
      missionIds,
      estimatedMissionIds,
      month,
      kpi,
    });
    // when fetching missions for a KPI, we should load all mission, no matter if real or estimated
    void fetchMissions({ variables: { missionIds: uniq([...missionIds, ...estimatedMissionIds]) } });
  };

  return (
    <div>
      <Title id="missionPlanning" textTitle={t('missionPlanningTitle')} />
      <SingleLineText text={t('missionPlanningDescription')} />
      <ContainerSelectDate>
        <ContainerDate>
          <SingleLineText text={t('pickDate', { ns: 'random' })} />
        </ContainerDate>
        <DatePicker
          views={['year', 'month']}
          value={datePickerValue}
          onAccept={(date): void => {
            setDate(date as Date);
          }}
          handleChange={(date): void => {
            setDatePickerValue(date as Date);
          }}
          label={t('missionPlanningStartDate')}
        />
      </ContainerSelectDate>
      <ContainerDiv>
        {popinState.kpi ? (
          <Modal additionnalCSS={{ width: '90%', overflowY: 'scroll' }} open={popinState.show} onClose={handleClose}>
            <DetailsKPIsMissions
              kpi={popinState.kpi}
              month={popinState.month}
              loadingMissions={loadingMissions}
              missions={missions?.allMissions?.results || []}
              estimatedMissionIds={popinState.estimatedMissionIds}
            />
          </Modal>
        ) : null}
        <MissionPlanning
          startDate={date}
          isLoading={loadingKpis}
          rawData={kpis?.dashboardMissionData || initialValue}
          openMissionsPopin={openMissionsPopin}
        />
      </ContainerDiv>
    </div>
  );
};

export default WithErrorBoundary(MissionPlanningDashboard);

const ContainerDiv = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginTop: '20px',
  width: '90%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
}));

const ContainerSelectDate = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '100px',
}));

const ContainerDate = styled('div')(() => ({
  marginRight: '10px',
}));
